import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import { WithContext as ReactTags } from 'react-tag-input';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getData, putData } from '../../modules/fetch';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CreateIcon from '@mui/icons-material/Create';
import { getCounts } from '../../api/getCounts';
import { InboxCountsContext } from '../../context/InboxCountsContext';
import { retrieve } from '../../modules/storage';
import '../../components/Accordion/styles.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { AccountDataContext } from '../../context/AccountDataContext';

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const Container = styled.div`
  position: absolute;
  width: 100%;
  min-width: 360px;
  flex-grow: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 70px);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  z-index: 5;
  @media ${mediaQueries.desktop} {
    //noinspection CssInvalidPropertyValue
    position: absolute;
    top: 0;
    right: 51px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    height: 100%;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 14px 10px;
`;
const UserPhoto = styled.div`
  width: 28px;
  height: 24px;
  margin-right: 16px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const UserName = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;
const ReactTagsWrapper = styled.div`
  .ReactTags__selected {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 14px 16px;
    border-bottom: 1px solid #ebeff2;
  }

  .ReactTags__tag {
    color: #6e6b7b;
    font-size: 11px;
    font-weight: bold;
    padding: 6px 18px 6px 10px;
    border: 1px solid #6e6b7b;
    border-radius: 20px;
    background-color: rgba(90, 111, 187, 0.06);
    margin-right: 15px;
    margin-top: 10px;
    line-height: 1;
    position: relative;

    .ReactTags__remove {
      border: none;
      background: transparent;
      padding: 0;
      margin-left: 3px;
      line-height: 1;
      font-size: 16px;
      position: absolute;
      right: 5px;
      top: 4px;
    }
  }

  .ReactTags__tagInput {
    input {
      border: none;
      font-size: 11px;
      font-weight: bold;
      border: none;
      background: transparent;
      outline: none;
      min-height: 20px;
      margin-top: 10px;
      cursor: pointer;

      ::placeholder {
        color: #2f80ed;
        opacity: 1;
      }
    }
  }

  .ReactTags__suggestions {
    position: absolute;
    background-color: #fff;
    padding: 5px 0;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    font-size: 1rem;
    letter-spacing: 0.5px;

    ul,
    li {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      padding: 8px 15px;
      cursor: pointer;

      mark {
        background: none;
        font-weight: 600;
      }

      :hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .ReactTags__activeSuggestion {
  }
`;

const AssigneeContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 14px 0;
`;
const AssigneeLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #9e9c9c;
  margin-right: 10px;

  svg {
    font-size: 12px;
    margin-right: 3px;
  }
`;
const Assignee = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  color: #4a4747;

  svg {
    font-size: 14px;
    margin-left: 5px;
    color: #9e9c9c;
  }
`;

const AddNewTag = ({ getInbox, tags, setTags, setUpdateTags, users }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [activeChat, setActiveChat, , , lastActiveChatId] = useContext<any>(ActiveChatContext);
  const [account,] = useContext(AccountDataContext);
  const [suggestions, setSuggestions] = useState([]);
  const [, setCounts] = useContext<any>(InboxCountsContext);
  const intl = useIntl();


  useEffect(() => {
    if (activeChat?._id?.$oid !== lastActiveChatId) {
      getSuggestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat]);

  const handleDelete = (i) => {
    const tagsUpdated = tags.filter((_, index) => index !== i);
    setTags(tagsUpdated);
    setUpdateTags(true);
  };

  const handleAddition = (tag) => {
    setTags([
      ...tags,
      {
        id: tag?.id?.replaceAll(' ', '_'),
        text: tag?.text?.replaceAll(' ', '_'),
      },
    ]);
    setUpdateTags(true);
    setInputValue('');
  };

  const handleInputChange = (value) => {
    setInputValue(String(value).replaceAll(' ', '_'));
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
    setUpdateTags(true);
  };

  const getSuggestions = () => {
    getData('/tags/listings')
      .then((data) => {
        if (data) {
          setSuggestions(data.data);
          setTags(activeChat?.tags || []);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAssigneeSelection = (id) => {
    setAnchorEl(null);
    if (activeChat?.entity === undefined || activeChat?._id?.$oid === undefined) {
      return;
    }
    putData(`/${activeChat?.entity}s/assignee/${activeChat?._id.$oid}`, {
      [activeChat.entity]: {
        assignee: id,
      },
    })
      .then((data) => {
        setActiveChat({ ...data.data, assignee: id });
        const filters = retrieve.json('filters');
        getCounts(filters, setCounts);
        getInbox();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Container>
      <User>
        <UserPhoto>
          {activeChat?.name ? <img src={activeChat?.logo} alt={activeChat?.name} /> : null}
        </UserPhoto>
        <UserName>{activeChat?.name}</UserName>
      </User>
      <ReactTagsWrapper>
        <ReactTags
          inline
          placeholder={intl.formatMessage({
            id: 'messages.addNewTag.tags',
            defaultMessage: 'messages.addNewTag.tags',
          })}
          autofocus={false}
          tags={tags}
          suggestions={suggestions}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          delimiters={delimiters}
          maxLength={30}
          minQueryLength={0}
          handleInputChange={handleInputChange}
          inputValue={inputValue}
        />
      </ReactTagsWrapper>
      <AssigneeContainer>
        <AssigneeLabel>
          <CheckBoxOutlinedIcon fontSize="small" />
          <FormattedMessage id="messages.addNewTag.assigned" defaultMessage="Assigned to: " />
        </AssigneeLabel>
        <Assignee
          onClick={(e) => setAnchorEl(e.currentTarget)}
          aria-label="assignee"
          aria-controls="assignee-menu"
          aria-haspopup="true"
        >
          {activeChat && 'assigneeName' in activeChat && activeChat?.assigneeName ? (
            <>
              {activeChat?.assigneeName}
              <CreateIcon fontSize="small" />
            </>
          ) : (
            <>
              <FormattedMessage
                id="messages.addNewTag.assigned.not"
                defaultMessage="Not Assigned"
              />
              <CreateIcon fontSize="small" />
            </>
          )}
        </Assignee>
        <Menu
          id="assignee-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem
            key={account?._id?.$oid}
            onClick={() => handleAssigneeSelection(account?._id?.$oid)}
          >
            <FormattedMessage
              id="messages.addNewTag.user_name"
              defaultMessage="{name} {surname}"
              values={{
                name: account?.name,
                surname: account?.surname,
              }}
            />
          </MenuItem>
          {users.map((user) => (
            <MenuItem
              key={user?._id?.$oid}
              onClick={() => handleAssigneeSelection(user?._id?.$oid)}
            >
              <FormattedMessage
                id="messages.addNewTag.user_name"
                defaultMessage="{name} {surname}"
                values={{
                  name: user?.name,
                  surname: user?.surname,
                }}
              />
            </MenuItem>
          ))}
        </Menu>
      </AssigneeContainer>
    </Container>
  );
};

export default React.memo(AddNewTag);
