import { API, API_BASIC_AUTH } from '../config';
import { isSafari } from 'react-device-detect';
import { clear, retrieve } from './storage';
import { ResponseError } from '../errors/response';
import jwtDecode from './jwt';
import { trackPromise } from 'react-promise-tracker';

export async function standardJsonResponse(
  request,
  response,
  redirectOnAuthFailed: boolean = true,
  redirectOnPasswordExpired: boolean = true,
) {
  if ('redirected' in response && response.redirected) {
    return response;
  }
  if ('status' in response && response.status > 399) {
    let message = '';
    let exception = null;
    const token = retrieve.string('token') ?? null;
    try {
      message = await response.json();
      exception = getError(message).exception;
      message = exception.message;
    } catch (e) {
      if (response.status > 399) {
        message = 'error.response.' + response.status;
      } else {
        message = e;
      }
    }

    if (redirectOnAuthFailed && response.status === 401) {
      clear();
      window.location.assign('/sign-in');
      return null;
    }

    if (redirectOnAuthFailed && response.status === 403) {
      return null;
    }

    if (redirectOnPasswordExpired && response.status === 412) {
      clear();
      let username = '';
      try {
        let json = await request.json();
        username = json?.username ?? '';
      } catch (e) {
      }
      window.location.assign('/reset-password/password-expired/' + username);
      return null;
    }

    throw new ResponseError(message, response, exception, token);
  } else {
    if (response.status !== 204) {
      return response.json();
    }
  }

  return '';
}

export function standardJsonResponseSync(
  response,
  redirectOnAuthFailed: boolean = true,
  redirectOnPasswordExpired: boolean = true,
) {
  if ('redirected' in response && response.redirected) {
    return response;
  }
  if ('status' in response && response.status > 399) {
    let message = '';
    let exception = null;
    const token = retrieve.string('token') ?? null;
    try {
      message = ('json' in response ? response?.json() : (response?.data ?? response ?? null));
      exception = getError(message).exception;
      message = exception.message;
    } catch (e) {
      if (response.status > 399) {
        message = 'error.response.' + response.status;
      } else {
        message = e;
      }
    }

    if (redirectOnAuthFailed && response.status === 401) {
      clear();
      window.location.assign('/sign-in');
      return null;
    }

    if (redirectOnPasswordExpired && response.status === 412) {
      clear();
      let username = '';
      try {
        if (token) {
          const _jwt = jwtDecode(token);
          username = _jwt?.username;
        }
      } catch (e) {
      }
      window.location.assign('/reset-password/password-expired/' + username);
      return null;
    }

    throw new ResponseError(message, response, exception, token);
  } else {
    if (response.status !== 204) {
      return response.json() ?? response.data ?? response.exception;
    }
  }

  return '';
}

export const getAuthenticationHeaders = (contentType) => {
  const headers = new Headers();
  const token = retrieve.string('token') ?? null;
  if (token && token !== '') {
    headers['Authorization'] = 'Bearer ' + token;
    if (isSafari) {
      headers.append('X-JWT', token);
    } else {
      headers.append('Authorization', 'Bearer ' + token);
    }
    // https://local.omcom.io/v1/?imitieren=anar@omcom.io
    const imitieren = retrieve.string('imitieren');
    if (imitieren) headers.append('X-IMITIEREN', imitieren);
    // @ts-ignore
  } else if (API_BASIC_AUTH !== '') {
    headers['Authorization'] = 'Basic ' + btoa(API_BASIC_AUTH);
  }
  if (contentType !== '') {
    headers.append('Content-Type', contentType);
  }
  return headers;
};

let baseApiUrl = API ?? '';
let lockPostQuery = {};

export const postData = async (
  url = '',
  data = {},
  redirectOnAuthFailed = true,
  redirectOnPasswordExpired = true,
) => {
  if (url in lockPostQuery) {
    return new Promise((resolve) => { resolve(null); })
  }
  lockPostQuery[url] = true;
  const request = new Request(`${baseApiUrl}${url}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: getAuthenticationHeaders('application/json'),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });
  const response = await trackPromise(fetch(request.clone()));
  const promise = standardJsonResponse(request, response, redirectOnAuthFailed, redirectOnPasswordExpired);
  delete lockPostQuery[url];
  return promise;
};

export const postImage = async (
  url = '',
  data,
  redirectOnAuthFailed = true,
  redirectOnPasswordExpired = true,
) => {
  const request = new Request(`${baseApiUrl}${url}`, {
    method: 'POST',
    headers: getAuthenticationHeaders(''),
    redirect: 'follow',
    body: data,
  });

  const response = await trackPromise(fetch(request.clone()));
  return standardJsonResponse(request, response, redirectOnAuthFailed, redirectOnPasswordExpired);
};

let lockGetQuery = {};

export const getData = async (
  url = '',
  redirectOnAuthFailed = true,
  redirectOnPasswordExpired = true,
) => {
  if (url in lockGetQuery) {
    return new Promise((resolve) => { resolve(null); })
  }
  lockGetQuery[url] = true;
  const request = new Request(`${baseApiUrl}${url}`, {
    headers: getAuthenticationHeaders('application/json'),
  });
  const response = await trackPromise(fetch(request.clone()));
  const promise = standardJsonResponse(request, response, redirectOnAuthFailed, redirectOnPasswordExpired);
  delete lockGetQuery[url];
  return promise;
};

export const getInNewWindow = (url: string = '', queryString: string = '') => {
  const token = retrieve.string('token') ?? null;
  let imitieren = retrieve.string('imitieren') ?? null;
  if (imitieren)
    imitieren = `&X-IMITIEREN=${imitieren}`;
  window.open(`${baseApiUrl}${url}?_jwt=${token}&${queryString}${imitieren}`, '_blank');
};

export const getDataByUrl = async (
  url = '',
  redirectOnAuthFailed = true,
  redirectOnPasswordExpired = true,
) => {
  const request = new Request(`${url}`, {
    headers: getAuthenticationHeaders('application/json'),
  });
  const response = await trackPromise(fetch(request.clone()));
  return standardJsonResponse(request, response, redirectOnAuthFailed, redirectOnPasswordExpired);
};

export const deleteData = async (
  url = '',
  redirectOnAuthFailed = true,
  redirectOnPasswordExpired = true,
) => {
  const request = new Request(`${baseApiUrl}${url}`, {
    method: 'DELETE',
    headers: getAuthenticationHeaders('application/json'),
  });
  const response = await trackPromise(fetch(request.clone()));

  if (response.status === 204) {
    return true;
  }
  return standardJsonResponse(request, response, redirectOnAuthFailed, redirectOnPasswordExpired);
};

export const putData = async (
  url = '',
  data = {},
  redirectOnAuthFailed = true,
  redirectOnPasswordExpired = true,
) => {
  const request = new Request(`${baseApiUrl}${url}`, {
    method: 'PUT',
    headers: getAuthenticationHeaders('application/json'),
    body: JSON.stringify(data),
  });
  const response = await trackPromise(fetch(request.clone()));
  return standardJsonResponse(request, response, redirectOnAuthFailed, redirectOnPasswordExpired);
};

export const patchData = async (
  url = '',
  data = {},
  redirectOnAuthFailed = true,
  redirectOnPasswordExpired = true,
) => {
  const request = new Request(`${baseApiUrl}${url}`, {
    method: 'PATCH',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: getAuthenticationHeaders('application/json'),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });
  const response = await trackPromise(fetch(request.clone()));
  return standardJsonResponse(request, response, redirectOnAuthFailed, redirectOnPasswordExpired);
};

export const getError = (data) => {
  data = data?.data ?? data;
  let message =
    data.exception?.previous?.exception?.message ??
    data.exception?.message ??
    data.details ??
    data.exception?.details ??
    '';
  let details = null;
  try {
    if (typeof message === 'string')
      message = JSON.parse(message);
    details = message?.details ?? '';
    message = message?.message ?? message;
  } catch (e) {
    details = "";
    console.error('/modules/fetch.js:getError:e', e);
  }

  let result = JSON.parse(JSON.stringify(data));
  if ('exception' in result) {
    result.exception.message = message;
    result.exception.details = details;
  } else {
    result.message = message;
    result.details = details;
  }

  console.debug('/modules/fetch.js:getError', result, data);
  return result;
};
