import { useMemo, useState } from 'react';

const useSortableData = (items = [], config = null) => {
  const [sortConfig, setSortConfig] = useState<Record<string, string>>(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (sortConfig.key === 'name') {
          return sortConfig.direction === 'ascending'
            ? `${a.name} ${a.countryCode}`.localeCompare(`${b.name} ${b.countryCode}`)
            : `${b.name} ${b.countryCode}`.localeCompare(`${a.name} ${a.countryCode}`);
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }
      });
    } else {
      sortableItems.sort((a, b) =>
        `${a.name} ${a.countryCode}`.localeCompare(`${b.name} ${b.countryCode}`),
      );
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default useSortableData;
