import { createContext, ReactNode, useState } from 'react';

export const MessagesPropsContext = createContext(null);

const MessagesPropsProvider = ({ children }: { children: ReactNode }) => {
  const [messagesProps, setMessagesProps] = useState<any>();
  const [showFeedbackFormPopup, setShowFeedbackFormPopup] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedFeedbackForm, setSelectedFeedbackForm] = useState(null);

  return (
    <MessagesPropsContext.Provider
      value={[
        messagesProps,
        setMessagesProps,
        showFeedbackFormPopup,
        setShowFeedbackFormPopup,
        selectedFeedbackForm,
        setSelectedFeedbackForm,
        isUpdate,
        setIsUpdate,
      ]}
    >
      {children}
    </MessagesPropsContext.Provider>
  );
};

export default MessagesPropsProvider;
