import { createContext, useEffect, useRef } from 'react';

export const MessageInputRefContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const messageInputRef = useRef();

  useEffect(() => {
    if (messageInputRef && messageInputRef?.current) {
      if (document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA') {
        console.log('document.activeElement', document.activeElement.tagName);
        // @ts-ignore
        messageInputRef?.current?.focus();
      }
    }
  });

  return (
    <MessageInputRefContext.Provider value={messageInputRef}>
      {children}
    </MessageInputRefContext.Provider>
  );
};
