import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthenticationHeaders } from '../modules/fetch';
import { API } from '../config';

export const feedbackApi = createApi({
  reducerPath: 'feedbackApi',
  baseQuery: fetchBaseQuery({ baseUrl: API }),
  tagTypes: ['Feedback'],
  endpoints: (builder) => ({
    feedbacks: builder.query({
      query: (id) => ({
        url: `/feedback/customer/${id}/listings`,
        headers: getAuthenticationHeaders('application/json'),
      }),
      providesTags: ['Feedback'],
    }),
    fetchTemplateFeedback: builder.query({
      query: (customerId) => ({
        url: `/feedback/template/${customerId}`,
        headers: getAuthenticationHeaders('application/json'),
      }),
    }),
    addFeedback: builder.mutation({
      query: ({ id, feedback }) => ({
        url: `/feedback/create/${id}`,
        method: 'POST',
        headers: getAuthenticationHeaders('application/json'),
        body: { feedback },
      }),
      invalidatesTags: ['Feedback'],
    }),
    updateFeedback: builder.mutation({
      query: ({ id, feedback }) => ({
        url: `/feedback/update/${id}`,
        method: 'PUT',
        headers: getAuthenticationHeaders('application/json'),
        body: { feedback },
      }),
      invalidatesTags: ['Feedback'],
    }),
  }),
});

export const {
  useFeedbacksQuery,
  useFetchTemplateFeedbackQuery,
  useAddFeedbackMutation,
  useUpdateFeedbackMutation,
} = feedbackApi;
