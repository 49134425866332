import { postData } from '../modules/fetch';
import { logMessage } from '../logger';

const postAccountConfirm = (requestBody, successCallback, errorCallback) => {
  postData('/account/confirm', requestBody)
    .then((data) => {
      if (data) {
        logMessage(data);
        successCallback(true);
      }
    })
    .catch((err) => {
      console.error(err);
      errorCallback(err.message);
    });
}

export default postAccountConfirm;
