import { Badge } from '@mui/material';
import { COLORS_ARRAY } from '../../../constants/ColorsArray';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Teasers from '../Teaser';
import { FormattedMessage } from 'react-intl';
import { getLogo } from '../../../api/getLogo';
import {
  Container,
  Content,
  CounterMsg,
  Image,
  Info,
  InfoItem,
  Initials,
  InitialsBadge,
  Message,
  Name,
  NavButton,
  OnHoverNav,
  OwnerName,
  Store,
  StoreContainer,
  StoreItem,
  StyledBox,
  Time,
} from './styles';

const Item = ({
  index,
  item,
  activeChat,
  teaser,
  teaserValues,
  shortMsg,
  currentStore,
  msgTime,
  isMenuOpen,
  handleChatOpen,
  handleOpenSmallChat,
}) => {
  const itemWasOpened = () => {
    const wasOpened = item?.wasOpened;
    if (wasOpened === undefined) return false;
    if (wasOpened === null) return false;
    if (typeof wasOpened === 'string') {
      switch (String(wasOpened).toLowerCase()) {
        case 'true':
          return true;
        case 'false':
        default:
          return false;
      }
    }
    return wasOpened;
  };

  return (
    <Container
      isMenuOpen={isMenuOpen}
      isActive={activeChat && activeChat?._id?.$oid === item?._id?.$oid}
      onClick={() => {
        handleChatOpen(item);
      }}
    >
      <Info>
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          overlap="circular"
          badgeContent={
            <InitialsBadge>
              {(item?.assigneeName &&
                item?.assigneeName
                  .split(' ')
                  .map((w) => String(w.charAt(0)).toUpperCase())
                  .join('')) ||
                '?'}
            </InitialsBadge>
          }
        >
          <StyledBox
            sx={{
              backgroundColor: `${COLORS_ARRAY[String(index).slice(-1)]}`,
            }}
          >
            <Initials>
              {item?.name &&
                item?.name
                  .split(' ')
                  .map((w) => String(w.charAt(0)).toUpperCase())
                  .join('')}
            </Initials>
          </StyledBox>
        </Badge>
        <InfoItem>
          <Name>{item?.name}</Name>
          <OwnerName>{item?.assigneeName ?? ''}</OwnerName>
          {/*<Type color={item?.stateColor}>{item?.state}</Type>*/}
        </InfoItem>
        {!!Number(item?.unreadCount) && <CounterMsg>{item?.unreadCount}</CounterMsg>}
        {activeChat?._id?.$oid !== item?._id?.$oid ? (
          <OnHoverNav>
            <NavButton
              aria-label="Open small chat"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenSmallChat(item);
              }}
            >
              <FullscreenIcon />
            </NavButton>
          </OnHoverNav>
        ) : null}
      </Info>
      <Content>
        <Message wasOpened={itemWasOpened()}>
          <Teasers type={teaser} item={item}>
            {teaser && (
              <FormattedMessage
                id={`messages.inbox.teaser.${teaser}`}
                defaultMessage={teaser}
                values={teaserValues}
              />
            )}
            {shortMsg}
          </Teasers>
        </Message>
      </Content>
      <StoreContainer>
        <StoreItem>
          <Image src={getLogo(item?.provider)} alt="" />
          {currentStore ? (
            <Store>{`${currentStore?.name} ${String(
              currentStore?.countryCode,
            ).toUpperCase()}`}</Store>
          ) : null}
        </StoreItem>
        <Time wasOpened={itemWasOpened()}>{msgTime}</Time>
      </StoreContainer>
    </Container>
  );
};
export default Item;
