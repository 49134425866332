import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import Messages from './pages/Messages';
import StoreManagement from './pages/StoreManagement';
import UserManagement from './pages/UserManagement';
import ConfirmEmail from './pages/ConfirmEmail';
import CreatePassword from './pages/CreatePassword';
import SettingsPage from './pages/Settings';
import Support from './pages/Support';
import Reports from './pages/Reports';
import Orders from './pages/Orders';
import Surveys from './pages/Surveys';
import en from './i18n/en_US.json';
import uk from './i18n/uk_UA.json';
import { WithoutToken } from './security';
import Nav from './components/Nav';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import InboxCountsContext from './context/InboxCountsContext';
import MessageContext from './context/MessageContext';
import NavContext from './context/NavContext';
import IsFilteringContext from './context/IsFilteringContext';
import { retrieve, store } from './modules/storage';
import ChatStatistics from './pages/ChatStatistics';
import { FBAccountDataContext } from './context/FBAccountDataContext';
import ContactsPage from './pages/Contacts';
import BroadcastPage from './pages/Broadcast';
import TemplatesManagement from './pages/TemplatesManagement';
import { JwtTokenContext } from './context/JwtTokenContext';
import jwtDecode from './modules/jwt';
import StoreDetails from './pages/StoreDetails';
import ExportChats from './pages/ExportChats';
import { IntlProvider } from 'react-intl';
import MessagesPropsProvider from './context/MessagesPropsContext';

export const MESSAGES = {
  En: en,
  Uk: uk,
};

const ALERT_OPTIONS = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

let notRedirectOnMatch = null;

function LocationChange() {
  const [, , { fbInit, fbGetLoginStatus }] = useContext<any>(FBAccountDataContext);
  const location = useLocation();

  useEffect(() => {
    const found = WithoutToken.some((element) => {
      return window.location.pathname.indexOf(element) !== -1;
    });
    if (!found) {
      // Load the JavaScript SDK asynchronously
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');

      // @ts-ignore
      window.fbAsyncInit = function () {
        // JavaScript SDK configuration and setup
        let fbApi = fbInit();

        // @ts-ignore
        fbGetLoginStatus(fbApi);
      };
    }
  }, [location]);
  return <></>;
}

function App() {
  const [lang, setLang] = useState('En');
  const [token, setToken, , setTokenParts] = useContext<any>(JwtTokenContext);

  useEffect(() => {
    if (!token) {
      const jwtToken = retrieve.string('token', null);
      if (jwtToken) {
        return () => {
          setToken(jwtToken);
          const tokenParts = jwtDecode(jwtToken);
          store.json('tokenParts', tokenParts);
          setTokenParts(tokenParts);
          store.string('uniqueId', tokenParts['uniqueId']);
        };
      }
    }

    if (window.location.pathname === '/') {
      if (token) {
        window.location.assign('/messages');
      } else {
        window.location.assign('/sign-in');
      }
    }
    if (!token) {
      if (notRedirectOnMatch === null) {
        let found = WithoutToken.some((element) => {
          return window.location.pathname.indexOf(element) !== -1;
        });
        if (!found) {
          window.location.assign('/sign-in');
        } else {
          notRedirectOnMatch = true;
        }
      }
    }
  }, [token]);

  useEffect(() => {
    const currentLang = retrieve.string('language', 'En');
    if (currentLang !== null) {
      setLang(currentLang);
    }
  }, []);

  return (
    <InboxCountsContext>
      <AlertProvider template={AlertTemplate} {...ALERT_OPTIONS}>
        <NavContext>
          <IsFilteringContext>
            <IntlProvider locale={lang} defaultLocale="En" messages={MESSAGES[lang]}>
              <MessageContext>
                <MessagesPropsProvider>
                  <Router>
                    <LocationChange />
                    <div>
                      <Routes>
                        <Route path="/users" element={<UserManagement />} />
                        <Route path="/stores" element={<StoreManagement />} />
                        <Route path="/store" element={<Navigate to="/stores" />} />
                        <Route path="/store/:id" element={<StoreDetails />} />
                        <Route path="/store/export/:storeId" element={<ExportChats />} />
                        <Route path="/templates" element={<TemplatesManagement />} />
                        <Route path="/logout" element={<Nav />} />
                        <Route path="/messages" element={<Messages />} />
                        <Route path="/messages/:folder" element={<Messages />} />
                        <Route path="/messages/:folder/:chatId" element={<Messages />} />
                        <Route path="/support/:page" element={<Support />} />
                        <Route path="/support" element={<Support />} />
                        <Route path="/contacts" element={<ContactsPage />} />
                        <Route path="/broadcast" element={<BroadcastPage />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route path="/settings/:page" element={<SettingsPage />} />
                        <Route path="/sign-up" element={<SignUp />} />
                        <Route
                          path="/reset-password"
                          element={<ResetPassword errorMessage={''} />}
                        />
                        <Route
                          path="/reset-password/password-expired/"
                          element={<ResetPassword errorMessage={'error.password_is_expired'} />}
                        />
                        <Route
                          path="/reset-password/password-expired/:email"
                          element={<ResetPassword errorMessage={'error.password_is_expired'} />}
                        />
                        <Route path="/create-password" element={<CreatePassword />} />
                        <Route path="/confirm-email" element={<ConfirmEmail />} />
                        <Route path="/sign-in" element={<SignIn />} />
                        <Route path="/dashboard" element={<Reports />} />
                        <Route path="/dashboard/reports" element={<Reports />} />
                        <Route path="/dashboard/chat-statistics" element={<ChatStatistics />} />
                        {/*<Route path="/analytics" element={<Analytics />} />*/}
                        <Route path="/surveys" element={<Surveys />} />
                        <Route path="/orders" element={<Orders />} />
                      </Routes>
                    </div>
                  </Router>
                  {/* <LangMenu langs={LANGS} currentLang={lang} onChange={setLang} /> */}
                </MessagesPropsProvider>
              </MessageContext>
            </IntlProvider>
          </IsFilteringContext>
        </NavContext>
        <script
          type="text/javascript"
          src="//laz-g-cdn.alicdn.com/sj/securesdk/0.0.3/securesdk_lzd_v1.js"
          id="J_secure_sdk_v2"
          data-appkey="102222"
        ></script>
      </AlertProvider>
    </InboxCountsContext>
  );
}

export default App;
