import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Loading } from './styles';
import MessageType from '../../constants/MessageType';
import Previous from './Message/Type/Previous';
import Text from './Message/Type/Text';
import Image from './Message/Type/Image';
import ImageWithText from './Message/Type/ImageWithText';
import Sticker from './Message/Type/Sticker';
import Video from './Message/Type/Video';
import Order from './Message/Type/Order';
import ProductsList from './Message/Type/ProductsList';
import Product from './Message/Type/Product';
import CartList from './Message/Type/CartList';
import System from './Message/Type/System';
import Voucher from './Message/Type/Voucher';
import RefundRequest from './Message/Type/RefundRequest';
import Unsupported from './Message/Type/Unsupported';
import Error from './Message/Type/Error';
import AutoReply from './Message/Type/AutoReply';
import { useIntl } from 'react-intl';
import BundleMessages from './Message/Type/BundleMessages';
import { useStoresQuery } from '../../api/storeAPI';
import Reaction from './Message/Type/Reaction';
import Template from './Message/Type/Template';

const Messages = ({
  activeChatIsCustomer,
  messages,
  currentUserId,
  resend,
  handleImagePopup,
  handleVideoPopup,
  customerDataStoreTimezone,
  getMoreMessages,
  totalMessages,
  activeStoreId,
  isFeedbackForm,
}) => {
  const intl = useIntl();
  const [timezone, setTimezone] = useState<any>();
  const [store, setStore] = useState<any>({});

  const { data: stores } = useStoresQuery(true);

  useEffect(() => {
    if (!customerDataStoreTimezone || Object.keys(customerDataStoreTimezone).length === 0) {
      if (activeStoreId) {
        const currentStore = stores?.data.find((store) => store?._id?.$oid === activeStoreId);
        setStore(currentStore);
        const tz = currentStore?.timezone;
        setTimezone(tz);
      } else if (messages?.[0]?.storeId) {
        const currentStore = stores?.data.find(
          (store) => store?._id?.$oid === messages?.[0]?.storeId,
        );
        setStore(currentStore);
        const tz = currentStore?.timezone;
        setTimezone(tz);
      }
    } else {
      setTimezone(customerDataStoreTimezone);
    }
  }, [customerDataStoreTimezone]);

  let client = false;
  const loadFunc = () => {
    getMoreMessages();
  };
  return (
    <InfiniteScroll
      loadMore={loadFunc}
      hasMore={messages.length < totalMessages}
      loader={
        isFeedbackForm ? null : (
          <Loading key={0}>
            Loading ({Math.ceil(messages.length / 10)} / {Math.ceil(totalMessages / 10)}) ...
          </Loading>
        )
      }
      isReverse
      useWindow={false}
      initialLoad={false}
    >
      {messages &&
        messages?.map((message) => {
          let more = null;
          client = activeChatIsCustomer
            ? message?.from?.resource === 'customer'
            : message?.from?.id !== currentUserId;
          if (message?.reaction) {
            more = Reaction({ client, message, more });
          }
          if (message?.body && message?.type) {
            switch (message.type) {
              case MessageType.TEXT:
              case MessageType.SURVEY_INTRO:
              case MessageType.SURVEY_QUESTION:
              case MessageType.SURVEY_ANSWER:
                return Text({
                  client,
                  message,
                  resend,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.BUNDLE_MESSAGE:
                return BundleMessages({
                  client,
                  message,
                  resend,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.IMAGE:
                return Image({
                  client,
                  message,
                  resend,
                  handleImagePopup,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.IMAGE_WITH_TEXT:
                return ImageWithText({
                  client,
                  message,
                  resend,
                  handleImagePopup,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.TEMPLATE:
                const messageTemplate = message?.body?.template?.id
                  ? message?.body?.template
                  : store?.messageTemplates?.find(
                      (tmpl) => tmpl?.name === message?.body?.template?.name,
                    );
                return Template({
                  client,
                  message,
                  resend,
                  customerDataStoreTimezone: timezone,
                  store,
                  messageTemplate,
                  more,
                });
              case MessageType.VIDEO:
                return Video({
                  client,
                  message,
                  resend,
                  handleVideoPopup,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.STICKER:
                return Sticker({
                  client,
                  message,
                  resend,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.ORDER:
                return Order({
                  client,
                  message,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.PRODUCT_LIST:
                return ProductsList({
                  client,
                  message,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.PRODUCT:
                return Product({
                  client,
                  message,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.CART_LIST:
                return CartList({
                  client,
                  message,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.SYSTEM:
                return System({
                  intl,
                  message,
                  more,
                });
              case MessageType.AUTO_REPLY:
                return AutoReply({
                  client,
                  message,
                  resend,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.VOUCHER:
                return Voucher({
                  client,
                  message,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.REFUND_REQUEST:
                return RefundRequest({
                  client,
                  message,
                  customerDataStoreTimezone: timezone,
                  more,
                });
              case MessageType.UNSUPPORTED:
                return Unsupported({
                  message,
                  more,
                });
              case MessageType.ERROR:
                return Error({
                  message,
                  more,
                });
              case MessageType.REACTION:
              case MessageType.SKIP:
                return '';
            }
          } else {
            return Previous({
              client,
              message,
              resend,
              handleImagePopup,
              customerDataStoreTimezone: timezone,
              more,
            });
          }
        })}
    </InfiniteScroll>
  );
};

export default React.memo(Messages);
