import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { mediaQueries } from '../../constants/breakpoints';
import { useContext, useEffect, useState } from 'react';
import { getData } from '../../modules/fetch';
import { getLogo } from '../../api/getLogo';
import { IsFilteringContext } from '../../context/IsFilteringContext';
import { store } from '../../modules/storage';
import { InboxCountsContext } from '../../context/InboxCountsContext';
import { getCounts } from '../../api/getCounts';

const Container = styled.div`
  position: absolute;
  z-index: 1299;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 61px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Shadow = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 0;
  background: rgba(37, 35, 35, 0.5);
`;
const StoreList = styled.div`
  position: absolute;
  top: 140px;
  left: 209px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 330px;
  min-height: calc(100vh - 150px);
  background: #fff;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.25);
  max-height: 400px;
  overflow-y: auto;
  min-width: 330px;
  padding: 15px;
  padding-top: 45px;
  z-index: 1500;
  @media ${mediaQueries.mobile} {
    left: 0;
    width: 100%;
    min-width: 0;
    max-width: 360px;
    border-radius: 0;
  }
`;

const StoreItem = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const StoreName = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const StoreIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;
const StyledIconButton = styled(IconButton)`
  position: absolute !important;
  top: 140px;
  left: 495px;
  color: #000 !important;
  z-index: 1501;

  svg {
    color: #000;
    font-size: 25px;
  }

  @media ${mediaQueries.mobile} {
    left: 320px;
  }
`;

interface Shop {
  storeName: string;
  provider: string;
  storeCountryCode: string;
  count: number;
  _id: {
    storeId: string;
  };
}

export const CountersPopup = ({
  currentState,
  filters,
  setFilters,
  getInbox,
  handleCountersClose,
}) => {
  const [shops, setShops] = useState<Shop[]>([]);
  const [, setIsFiltering] = useContext(IsFilteringContext);
  const [, setCounts] = useContext(InboxCountsContext);

  const handleStoreClick = (shopId: string) => {
    const newFilters = { ...filters, shops: [shopId] };
    setFilters(newFilters);
    store.json('filters', newFilters);
    getCounts(newFilters, setCounts);
    setIsFiltering(true);
    getInbox(false, null, 0, null, newFilters);
    handleCountersClose();
  };

  useEffect(() => {
    getData(`/chats/counts/stores/${currentState}`)
      .then((data) => {
        const sortedShops = data.data.sort((a, b) => {
          if (b.count !== a.count) {
            return b.count - a.count;
          }
          return a.storeName.localeCompare(b.storeName);
        });
        setShops(sortedShops);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [currentState]);

  return (
    <Container>
      <Shadow onClick={handleCountersClose} />
      <StoreList>
        {shops.map((shop, index) => (
          <StoreItem key={index} onClick={() => handleStoreClick(shop._id.storeId)}>
            <StoreName>
              <StoreIcon src={getLogo(shop.provider)} alt="shop icon" />
              {`${shop.storeName} (${shop.storeCountryCode})`}
            </StoreName>
            {shop.count > 0 && <div className="store-count">{shop.count}</div>}
          </StoreItem>
        ))}
      </StoreList>
      <StyledIconButton aria-label="hide" onClick={handleCountersClose}>
        <CloseIcon />
      </StyledIconButton>
    </Container>
  );
};

export default CountersPopup;
