import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
  }
`;
export const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  color: #050d42;
`;
export const Text = styled.div`
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: #050d42;
`;
export const StyledIconButton = styled(IconButton)`
  svg {
    color: #9d9d9d;
    font-size: 25px;
  }
`;
