import React, { useContext, useEffect, useState } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import { getData } from '../../modules/fetch';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import DoneIcon from '@mui/icons-material/Done';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import Button from '@mui/material/Button';
import renderDate from '../../modules/renderDate';
import '../../components/Accordion/styles.css';
import { FormattedMessage } from 'react-intl';
import { GlobalHandlersContext } from '../../context/GlobalHandlersContext';
import { AccordionItem, AccordionItemButton, AccordionItemHeading, } from 'react-accessible-accordion';
import {
  AccordionTitle,
  Buttons,
  Container,
  Img,
  ImgContainer,
  Info,
  Item,
  ItemClick,
  Order,
  OrderDetails,
  PerItem,
  Price,
  SKU,
  StyledAccordion,
  StyledAccordionItemPanel,
  SubItem,
  Title,
} from './style';
import { formatPrice } from '../../modules/price';
import { toTitleCase } from '../../modules/string';

const CustomerOrders = () => {
  const [activeChat] = useContext(ActiveChatContext);
  const [orders, setOrders] = useState([]);
  const [globalHandlers] = useContext<any>(GlobalHandlersContext);
  const [panels, setPanels] = useState([]);

  useEffect(() => {
    getData(`/${activeChat?.entity}s/orders/${activeChat?._id?.$oid}/1`)
      .then((response) => {
        if (response)
          setOrders(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSend = (id) => {
    globalHandlers.handleMessageSend('', 'order', id);
  };

  const handleOpenPopupInfo = (group) => () => {
    setPanels({ ...panels, [group]: !panels?.[group] });
  }

  return (
    <Container>
      {orders?.length ? (orders.map((order, index) => (
          <StyledAccordion allowZeroExpanded key={`order-item-${index}`}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionTitle>
                    <h5>Order: {order?.orderId}</h5>
                  </AccordionTitle>
                </AccordionItemButton>
              </AccordionItemHeading>
              <StyledAccordionItemPanel>
                {order?.orderItemsSummary?.map((item, i) => {
                  const orderItem = order?.orderItems?.find((good) => good.orderItemId == (item?.orderItemId || item?.id));
                  return (
                    <Order key={`order-orderItems-${i}`}>
                      <ImgContainer>
                        <Img src={item?.imageUrl || item?.image || orderItem?.imageUrl || orderItem?.image} alt={item?.name || orderItem?.name || ''} />
                      </ImgContainer>
                      <Info>
                        <Title>{item?.name || orderItem?.name}</Title>
                        <SKU>
                          <FormattedMessage
                            id="messages.customerOrders.accordion.sku"
                            defaultMessage="SKU: "
                          /><br />
                          {item?.sku || item?.skuId || 'n/a'}
                        </SKU>
                        <Price>
                          <PerItem>
                            {formatPrice(order?.store?.countryCode, order?.currency, orderItem?.price || item?.price)} x {item?.quantity || item?.amount || 1}
                          </PerItem>
                        </Price>
                      </Info>
                    </Order>
                  )
                })}
                <OrderDetails>
                  <Item>
                    <div>
                      <LocalAtmOutlinedIcon />
                      <strong><FormattedMessage
                        id="messages.customerOrders.details.total"
                        defaultMessage="Total"
                      /></strong>
                    </div>
                    <div>
                      <strong>{formatPrice(order?.store?.countryCode, order?.currency, order?.price)}</strong>
                    </div>
                  </Item>
                  <Item>
                    <div>
                      <CreditCardOutlinedIcon />
                      <FormattedMessage
                        id="messages.customerOrders.details.paymentMethod"
                        defaultMessage="Payment Method"
                      />
                    </div>
                    <div>
                      {order?.paymentMethod ? toTitleCase(order?.paymentMethod) : 'n/a'}
                    </div>
                  </Item>
                  <Item>
                    <div>
                      <DoneIcon />
                      <FormattedMessage
                        id="messages.customerOrders.details.status"
                        defaultMessage="Order Status"
                      />
                    </div>
                    <div>{toTitleCase(order?.status || '')}</div>
                  </Item>
                  <Item>
                    <div>
                      <CalendarTodayIcon />
                      <FormattedMessage
                        id="messages.customerOrders.details.created"
                        defaultMessage="Created"
                      />
                    </div>
                    <div>{renderDate(order?.orderCreatedAt?.date ?? order?.orderDate?.date, order?.store?.timezone?.name) || 'n/a'}</div>
                  </Item>
                  <Item>
                    <div>
                      <LocalShippingOutlinedIcon />
                      <FormattedMessage
                        id="messages.customerOrders.details.shippingFee"
                        defaultMessage="Shipping Fee"
                      />
                    </div>
                    <div>
                      {formatPrice(order?.store?.countryCode, order?.currency, order?.shippingFee)}
                    </div>
                  </Item>
                  <Item>
                    <div>
                      <TravelExploreOutlinedIcon />
                      <FormattedMessage
                        id="messages.customerOrders.details.tracking"
                        defaultMessage="Tracking Number"
                      />
                    </div>
                    <div>{order?.trackingNumber || 'n/a'}</div>
                  </Item>
                  <Item>
                    <div>
                      <CalendarTodayIcon />
                      <FormattedMessage
                        id="messages.customerOrders.details.delivery_exp"
                        defaultMessage="EXPECTED DELIVERY"
                      />
                    </div>
                    <div>
                      {renderDate(order?.estimatedDeliveryTime?.date, order?.store?.timezone?.name) || 'n/a'}
                    </div>
                  </Item>
                  <Item>
                    <div>
                      <LocalShippingIcon />
                      <FormattedMessage
                        id="messages.customerOrders.details.delivery_by"
                        defaultMessage="Delivered By"
                      />
                    </div>
                    <div>{order?.deliveredBy || 'n/a'}</div>
                  </Item>
                  <Item>
                    <div>
                      <CurrencyExchangeOutlinedIcon />
                      <FormattedMessage
                        id="messages.customerOrders.details.refundReason"
                        defaultMessage="Refund Reason"
                      />
                    </div>
                    <div>
                      {order?.refundReason || 'n/a'}
                    </div>
                  </Item>
                  {Object.keys(order?.additionalInformation ?? {}).map((group, i) => (
                    <>
                      <ItemClick key={`order-additional-group-${i}`} onClick={handleOpenPopupInfo(group)}>
                        <div>
                          {panels?.[group] === true ? <ExpandMoreOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
                          <FormattedMessage
                            id={'messages.customerOrders.details.' + group}
                            defaultMessage={toTitleCase(group)}
                          />
                        </div>
                        <div>
                        </div>
                      </ItemClick>
                      {panels?.[group] === true ? (Array.isArray(order?.additionalInformation?.[group]) ?
                        order?.additionalInformation?.[group].map((objects, k) => (
                          Object.keys(objects)?.map((field, j) => (
                            <SubItem key={`order-additional-group-${i}-${j}-${k}`}>
                              <div>
                                {toTitleCase(field)}
                              </div>
                              {objects?.[field] && (typeof objects?.[field] === 'object') ? (
                                <pre>
                                    {JSON.stringify(objects?.[field], null, 1)
                                      .replaceAll(/[\[\]{}",]/g, '')
                                      .replaceAll(/^[\n\s]*/g, '  ')
                                      .replaceAll(/[\n\s]*$/g, '')
                                      .replaceAll(/\s\n\s\n/g, '\n')
                                      .replaceAll(/^\s+\n*$/gm, '')
                                    }
                                  </pre>
                              ) : (
                                <div>
                                  {objects?.[field] ? objects?.[field] : (objects?.[field] === 0 ? 0 : 'n/a')}
                                </div>
                              )}
                            </SubItem>
                          )))) :
                        Object.keys(order?.additionalInformation?.[group] ?? {}).map((field, j) => (
                          <SubItem key={`order-additional-group-${i}-${j}`}>
                            <div>
                              {toTitleCase(field)}
                            </div>
                            <div>
                              {order?.additionalInformation?.[group]?.[field] ? JSON.stringify(order?.additionalInformation?.[group]?.[field], null, 2).replaceAll(/[\{\}\"\,]/g, '') : (order?.additionalInformation?.[group]?.[field] === 0 ? 0 : 'n/a')}
                            </div>
                          </SubItem>
                        ))) : ('')}
                    </>
                  ))}
                  <Buttons>
                    {/* <Button size="small" variant="outlined" color="primary">Details</Button> */}
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => handleSend(order?._id.$oid)}
                    >
                      <FormattedMessage
                        id="messages.customerOrders.details.button.send"
                        defaultMessage="Send"
                      />
                    </Button>
                  </Buttons>
                </OrderDetails>
              </StyledAccordionItemPanel>
            </AccordionItem>
          </StyledAccordion>
        ))
      ) : (
        <h4>
          <FormattedMessage
            id="messages.customerOrders.title.no_orders"
            defaultMessage="No Orders"
          />
        </h4>
      )}
    </Container>
  );
};

export default React.memo(CustomerOrders);
