export const DOMAIN = 'omcom.io';
export const API_DOMAIN = 'rest.' + DOMAIN;
export const BASE_DOMAIN = '//' + API_DOMAIN;
export const BASE_URL = 'https:' + BASE_DOMAIN;
export const API_VERSION = '/v1';
export const API = BASE_URL + API_VERSION;
export const API_BASIC_AUTH = '';
export const API_LOGIN = '';
export const API_PASS = '';
export const WAMP_URL = 'wss:' + BASE_DOMAIN + '/websocket/';
export const SENTRY_DSN = '';
export const ENV = 'prod';

