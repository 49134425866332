import { useContext } from 'react';
import { Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import { getData } from '../../modules/fetch';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { FBAccountDataContext } from '../../context/FBAccountDataContext';
import { logMessage } from '../../logger';

const Container = styled.div`
  width: 100%;
  min-height: 200px;
  padding: 25px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.div`
  color: #000;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 40px;
`;
const Terms = styled.div`
  margin-top: 40px;
  text-align: center;
`;

const Facebook = () => {
  const [loginStatus, setLoginStatus] = useContext<any>(FBAccountDataContext);
  const intl = useIntl();

  // const changePermissions = () => {
  //   // @ts-ignore
  //   FB.api('/me/permissions', 'DELETE', () => {
  //     launchWhatsAppSignup();
  //   });
  // };

  function uploadFbAccessToken(accessToken: string, userId) {
    getData(`/platform/whatsapp/init/${accessToken}/${userId}`)
      .then((data) => {
        if (data) {
          logMessage(data);
          document.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

// Facebook Login with JavaScript SDK
  const launchWhatsAppSignup = () => {
    // Conversion tracking code
    // @ts-ignore
    // fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {
    //     appId: '5098984906889107',
    //     feature: 'whatsapp_embedded_signup',
    //   });

    // Launch Facebook login
    // @ts-ignore
    FB.login(
      (response) => {
        if (response.authResponse) {
          // @ts-ignore
          const accessToken = response.authResponse.accessToken;
          const userId = response.authResponse.userID;
          setLoginStatus(response);
          uploadFbAccessToken(accessToken, userId);
          //Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        scope:
          'business_management,whatsapp_business_management,whatsapp_business_messaging',
        return_scopes: true,
        extras: {
          feature: 'whatsapp_embedded_signup',
          setup: {},
        },
      },
    );
  };

  // Facebook Logout with JavaScript SDK
  const launchWhatsAppSignout = () => {
    // Conversion tracking code
    // @ts-ignore
    // fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {
    //     appId: '5098984906889107',
    //     feature: 'whatsapp_embedded_signup',
    //   });

    // Launch Facebook login
    // @ts-ignore
    FB.logout(function (response) {
      console.log(response);
      alert('FB.logout');
    });
  };

  console.log({ launchWhatsAppSignout });

  return (
    <>
      <div style={{ display: 'none' }}>
        {loginStatus && loginStatus?.status !== 'unknown' && JSON.stringify(loginStatus)}
      </div>
      {loginStatus?.status !== '' ? (
        <Container>
          {loginStatus?.status === 'unknown' ? (
            <>
              <Title>
                <FormattedMessage
                  id="storeManagement.fbpopup.title"
                  defaultMessage="Omcom requires certain permissions to build automations with Messenger, Instagram, and WhatsApp. Click the button to grant them."
                />
              </Title>
              <Button
                variant="contained"
                startIcon={<FacebookIcon />}
                onClick={launchWhatsAppSignup}
              >
                <FormattedMessage id="storeManagement.fbpopup.continue" defaultMessage="Continue With Facebook" />
              </Button>
            </>
          ) : (
            <>
              <Title dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  {
                    id: 'storeManagement.fbpopup.loggedinTitle',
                    defaultMessage: 'storeManagement.fbpopup.loggedinTitle',
                  },
                ),
              }}
              />
              <Button
                variant="contained"
                startIcon={<FacebookIcon />}
                onClick={launchWhatsAppSignup}
              >
                <FormattedMessage id="storeManagement.fbpopup.add_new" defaultMessage="Add new account" />
              </Button>
              {/*<br />*/}
              {/*<Button*/}
              {/*  variant="contained"*/}
              {/*  startIcon={<FacebookIcon />}*/}
              {/*  onClick={changePermissions}*/}
              {/*>*/}
              {/*  <FormattedMessage id='storeManagement.fbpopup.edit_exists' defaultMessage='Edit permissions for existing account' />*/}
              {/*</Button>*/}
            </>
          )}
          <Terms
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id: 'storeManagement.fbpopup.terms.title',
                  defaultMessage: 'storeManagement.fbpopup.terms.title',
                },
                {
                  terms: intl.formatMessage({
                    id: 'storeManagement.fbpopup.terms.terms',
                    defaultMessage: 'storeManagement.fbpopup.terms.terms',
                  }),
                  policy: intl.formatMessage({
                    id: 'storeManagement.fbpopup.terms.policy',
                    defaultMessage: 'storeManagement.fbpopup.terms.policy',
                  }),
                },
              ),
            }}
          />
        </Container>
      ) : null}
      {/* <FBButton onClick={launchWhatsAppSignout}>Logout with Facebook</FBButton> */}
    </>
  );
};

export default Facebook;
