import React, { useContext, useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import Messages from '../../components/Messages';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AutoResponses from '../../components/Responses';
import { getData, getError, postData, postImage, putData } from '../../modules/fetch';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import SendMessage from '../../components/SendMessage';
import Popup from '../../components/Popup';
import ReportForm from '../ReportForm';
import PanelCustomer from '../PanelCustomer';
import { FormattedMessage, useIntl } from 'react-intl';
import { GlobalLoadingContext } from '../../context/GlobalLoadingContext';
import { FilePicker } from 'react-file-picker';
import {
  ArrowsButton,
  BackContainer,
  Buttons,
  Container,
  CopyRight,
  CurrentOrder,
  CurrentOrderName,
  HeaderContainer,
  Image,
  InfoItem,
  Initials,
  MessagesContainer,
  MessagesEnd,
  MoreButton,
  Name,
  Responses,
  ResponsesContainer,
  ResponsesContent,
  ResponsesIcon,
  SelectInitials,
  SendContainer,
  SendInputs,
  StoreName,
  StyledBox,
  StyledFastResponseButton,
  StyledImage,
  StyledMenuItem,
  StyledPopup,
  StyledVideo,
  SyncButton,
  UserInfo,
} from './styles';
import SurveysSelect from '../../components/SurveysSelect';
import { Badge, FormControl, Select } from '@mui/material';
import { COLORS_ARRAY } from '../../constants/ColorsArray';
import { getLogo } from '../../api/getLogo';
import { retrieve } from '../../modules/storage';
import { getCounts } from '../../api/getCounts';
import { InboxCountsContext } from '../../context/InboxCountsContext';
import SubMenu from '../../components/SubMenu';
import { GlobalHandlersContext } from '../../context/GlobalHandlersContext';
import PopupAutoWidth from '../../components/PopupAutoWidth';
import { logMessage } from '../../logger';
import FeedbackForm from '../FeedbackForm';
import { MessagesPropsContext } from '../../context/MessagesPropsContext';

const Chat = ({
  messages,
  responsesData,
  handleChatClose,
  currentUser,
  handleEscalate,
  handleResolved,
  handleFollowUp,
  wampHandlers,
  getMoreMessages,
  totalMessages,
  scrollMessages,
  getInbox,
  findMessageBySendId,
  users,
  tokenValues,
  setShowChat,
  openSmallChat,
  activeStore,
}) => {
  const [, setLoading] = useContext(GlobalLoadingContext);
  const [showResponses, setShowResponses] = useState(false);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const [anchorElFolders, setAnchorElFolders] = useState(null);
  const [activeChat, setActiveChat, , , lastActiveChatId] = useContext<any>(ActiveChatContext);
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [showSurveysPopup, setShowSurveysPopup] = useState(false);
  const [imagePopupURL, setImagePopupURL] = useState('');
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [videoPopupURL, setVideoPopupURL] = useState({ autoplay: false, poster: '', url: '' });
  const [showReportFormPopup, setShowReportFormPopup] = useState(false);
  const [, , showFeedbackFormPopup, setShowFeedbackFormPopup, , , , setIsUpdate] =
    useContext(MessagesPropsContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [activeChatIsCustomer, setActiveChatIsCustomer] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(currentUser?._id?.$oid);
  const [customerDataStoreTimezone, setCustomerDataStoreTimezone] = useState({});
  const [activePanel, setActivePanel] = useState(false);
  const [activeContent, setActiveContent] = useState(null);
  const [checkIsSentMessage, runCheckIsSentMessage] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [isPreviewTicket, setIsPreviewTicket] = useState(false);
  const [surveysList, setSurveysList] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [assignee, setAssignee] = useState('');
  const [, setCounts] = useContext<any>(InboxCountsContext);
  const [globalHandlers, setGlobalHandlers] = useContext<any>(GlobalHandlersContext);

  const intl = useIntl();

  const [, setMessagesProps] = useContext(MessagesPropsContext);
  const handleFoldersMenuOpen = (event) => {
    setAnchorElFolders(event.currentTarget);
  };
  const handleFoldersMenuClose = () => {
    setAnchorElFolders(null);
  };
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView(false);
    }, 100);
  };

  useEffect(() => {
    // setWsMessage(({topic, message}) => {
    //   alert(topic + ' ' + message);
    // });
    setCustomerDataStoreTimezone(activeStore?.timezone ?? {});
    clearFileInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollMessages]);

  useEffect(() => {
    if (activeChat?._id?.$oid !== lastActiveChatId) {
      setActiveChatIsCustomer(activeChat?.entity === 'customer');

      setActivePanel(false);
      setAssignee(activeChat?.assignee);
      setGlobalHandlers({ ...globalHandlers, handleMessageSend });
    }
    setMessagesProps({
      activeChatIsCustomer,
      messages,
      currentUserId,
      resend,
      handleImagePopup,
      handleVideoPopup,
      customerDataStoreTimezone,
      getMoreMessages,
      totalMessages,
      activeStoreId: activeStore?._id?.$oid,
    });
  }, [activeChat]);

  useEffect(() => {
    if (activeChat?._id?.$oid !== lastActiveChatId) {
      setActiveChatIsCustomer(activeChat?.entity === 'customer');
      setCurrentUserId(currentUser?._id?.$oid);
      setCustomerDataStoreTimezone(activeStore?.timezone ?? {});
    }
  }, [activeChat, currentUser]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (checkIsSentMessage) {
      const item = findMessageBySendId(checkIsSentMessage);
      if (item !== undefined && !item?._id?.$oid) {
        getData(`/messages/send-id/${item?.sendId}`)
          .then((response) => {
            if (response) {
              wampHandlers.message([{ message: response.data }]);
              if (response.data.providerMessageId !== null) {
              } else {
                runCheckIsSentMessage(null);
                setTimeout(() => {
                  runCheckIsSentMessage(item?.sendId);
                }, 5000);
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [checkIsSentMessage]);
  const handleMessageSend = (messageText, messageType = 'text', entityID = '') => {
    const message = applyTokensOnMessage(messageText);
    let body: { text: any } | { image: { url: any } } | null;
    switch (messageType) {
      case 'image':
        body = { image: { url: message } };
        break;
      case 'text':
        body = { text: message };
        break;
      default:
        body = null;
    }
    const msg = {
      content: message,
      body: body,
      entityId: entityID, //"._id" API end point /file return
      type: messageType, //"text" || "image" || "order",
      sendId: activeChat?._id?.$oid + Date.now() + Math.floor(Math.random() * 1000000),
      chatId: activeChat?._id?.$oid,
      title: `${currentUser?.name} ${currentUser?.surname}`,
      from: {
        id: currentUser?._id?.$oid,
        resource: 'user',
      },
      entity: 'customer',
    };
    wampHandlers.message([{ message: msg }]);
    if (activeChat?.entity && activeChat?._id?.$oid) {
      setLoading(false);
      postData(`/messages/send/${activeChat?.entity}/${activeChat?._id?.$oid}`, msg)
        .then(() => {
          setTimeout(() => {
            runCheckIsSentMessage(msg?.sendId);
          }, 5000);
        })
        .catch((responseError) => {
          console.error(responseError);
        })
        .finally(() => {
          setLoading(true);
        });
    }
  };

  const resend = (messageId) => {
    postData(`/messages/resend/${messageId}`).catch((err) => {
      console.error(err);
    });
  };

  const applyTokensOnMessage = (message) => {
    let messageBuf = message;
    Object.entries(tokenValues || []).map(
      (token) => (messageBuf = messageBuf?.replaceAll(`[[{"value":"${token[0]}"}]]`, token?.[1])),
    );
    return messageBuf;
  };

  const handleFastResponseSend = (e) => {
    let fastResponseMessage = responsesData.find(
      (item) => item.title === e.target.textContent,
    )?.message;
    fastResponseMessage = applyTokensOnMessage(fastResponseMessage);
    handleMessageSend(fastResponseMessage);
  };

  const handleFollowUpMenuSelection = (value) => {
    handleFollowUp(value);
  };
  const handleEscalateMenuSelection = () => {
    handleEscalate();
    handleFoldersMenuClose();
  };
  const handleCreateReportMenu = () => {
    setShowReportFormPopup(true);
    handleFoldersMenuClose();
  };
  const handleCreateFeedbackMenu = () => {
    setShowFeedbackFormPopup(true);
    setIsUpdate(false);
    setActiveChatIsCustomer(activeChat?.entity === 'customer');
    handleFoldersMenuClose();
  };
  const handleCloseMenu = () => {
    handleResolved();
    setShowChat(false);
    handleFoldersMenuClose();
  };
  const handleSync = () => {
    getData(`/customers/resync/${activeChat?._id?.$oid}/messages`)
      .then((response) => {
        if (response) {
          logMessage('response.data', response.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleCloseFullscreen = () => {
    openSmallChat(activeChat);
    setShowChat(false);
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const handleImageUpload = (file) => {
    const formData = new FormData();
    formData.append('image', file);

    postImage(`/files/image/${activeChat?.storeId}`, formData)
      .then((response) => {
        handleMessageSend('', 'image', response.data._id.$oid);
        clearFileInput();
      })
      .catch((responseError) => {
        const exception = getError(responseError.exception);
        alert(intl.formatMessage({ id: exception.details, defaultMessage: exception.details }));
        console.error(responseError);
      });
  };

  const handleImagePopup = (url) => {
    setImagePopupURL(url);
    setShowImagePopup(true);
  };

  const handleVideoPopup = ({ autoplay, poster, url }) => {
    setVideoPopupURL({ autoplay, poster, url });
    setShowVideoPopup(true);
  };

  const closePopup = () => {
    setIsPreview(false);
    setShowReportFormPopup(false);
  };

  const togglePanels = (panel) => {
    if (!activePanel) {
      setActivePanel(true);
      setActiveContent(panel);
    } else if (activePanel && activeContent === panel) {
      setActivePanel(false);
      setActiveContent(null);
    } else if (activePanel && activeContent !== panel) {
      setActiveContent(panel);
    }
  };
  const handleSurveysSelectOpen = () => {
    getData(`/surveys/available/customer/${activeChat?._id?.$oid}`)
      .then((response) => {
        if (response) {
          setSurveysList(response.data);
          setShowSurveysPopup(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleSelectedSurveySend = () => {
    const msg = {
      type: 'survey',
      entityId: selectedSurvey,
      sendId: activeChat?._id?.$oid + Date.now() + Math.floor(Math.random() * 1000000),
      chatId: activeChat?._id?.$oid,
      title: `${currentUser?.name} ${currentUser?.surname}`,
      from: {
        id: currentUser?._id?.$oid,
        resource: 'user',
      },
    };
    postData(`/messages/send/customer/${activeChat?._id?.$oid}`, msg)
      .then((response) => {
        if (response) {
          logMessage('response', response);
          setTimeout(() => {
            runCheckIsSentMessage(msg?.sendId);
          }, 5000);
        }
      })
      .catch((responseError) => {
        console.error(responseError);
      });
    setShowSurveysPopup(false);
  };
  const handleChangeAssignee = (id) => {
    setAssignee(id);
    putData(`/${activeChat?.entity}s/assignee/${activeChat?._id.$oid}`, {
      [activeChat.entity]: {
        assignee: id,
      },
    })
      .then((data) => {
        setActiveChat({ ...data.data });
        const filters = retrieve.json('filters');
        getCounts(filters, setCounts);
        getInbox();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // @ts-ignore
  return (
    <>
      {showSurveysPopup && (
        <PopupAutoWidth
          isPreviewTicket={false}
          setIsPreviewTicket={setIsPreviewTicket}
          handleClose={() => setShowSurveysPopup(false)}
        >
          <SurveysSelect
            surveysList={surveysList}
            selectedSurvey={selectedSurvey}
            setSelectedSurvey={setSelectedSurvey}
            setShowSurveysPopup={setShowSurveysPopup}
            handleSelectedSurveySend={handleSelectedSurveySend}
          />
        </PopupAutoWidth>
      )}
      {showImagePopup && (
        <Popup
          isPreviewTicket={isPreviewTicket}
          setIsPreviewTicket={setIsPreviewTicket}
          isMedia={true}
          handleClose={() => setShowImagePopup(false)}
        >
          <img src={imagePopupURL} alt="" style={{ maxHeight: '79vh' }} />
        </Popup>
      )}
      {showVideoPopup && (
        <Popup
          isPreviewTicket={isPreviewTicket}
          setIsPreviewTicket={setIsPreviewTicket}
          isMedia={true}
          handleClose={() => setShowVideoPopup(false)}
        >
          <StyledVideo
            controls
            src={videoPopupURL?.url || ''}
            poster={videoPopupURL?.poster || ''}
            autoPlay={videoPopupURL?.autoplay || false}
          />
        </Popup>
      )}
      {showReportFormPopup && (
        <StyledPopup
          isPreviewTicket={isPreviewTicket}
          setIsPreviewTicket={setIsPreviewTicket}
          handleClose={closePopup}
        >
          <ReportForm
            onClose={() => setShowReportFormPopup(false)}
            isPreview={isPreview}
            setIsPreview={setIsPreview}
            isPreviewTicket={false}
            content={null}
            customerId={activeChat._id.$oid}
          />
        </StyledPopup>
      )}
      {showFeedbackFormPopup && (
        <StyledPopup
          isPreviewTicket={isPreviewTicket}
          setIsPreviewTicket={setIsPreviewTicket}
          handleClose={() => setShowFeedbackFormPopup(false)}
        >
          <FeedbackForm
            onClose={() => setShowFeedbackFormPopup(false)}
            customerId={activeChat._id.$oid}
          />
        </StyledPopup>
      )}
      <Container>
        <HeaderContainer>
          <BackContainer>
            <IconButton aria-label="search" onClick={handleChatClose}>
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
          </BackContainer>
          <UserInfo>
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              overlap="circular"
              badgeContent={<Image src={getLogo(activeChat?.store?.provider)} alt="" />}
            >
              <StyledBox
                sx={{
                  backgroundColor: `${COLORS_ARRAY[String(10).slice(-1)]}`,
                }}
              >
                <Initials>
                  {activeChat?.name &&
                    activeChat?.name
                      .split(' ')
                      .map((w) => String(w.charAt(0)).toUpperCase())
                      .join('')}
                </Initials>
              </StyledBox>
            </Badge>
            <InfoItem>
              <Name>
                {activeChat?.name ?? ''} ({activeChat?.provider === 'whatsapp' ? '+' : ''}
                {activeChat?.buyerId ?? ''})
              </Name>
              <StoreName>
                {activeChat?.store?.name?.toLowerCase() ?? ''}{' '}
                {activeChat?.store?.countryCode?.toLowerCase() ?? ''}
              </StoreName>
            </InfoItem>
          </UserInfo>
          <FormControl style={{ width: 200 }}>
            <Select id="assignee-select" className={'assignee-select'} value={assignee}>
              {users?.map((user, index) => (
                <MenuItem
                  key={user?._id?.$oid}
                  value={user?._id?.$oid}
                  onClick={() => handleChangeAssignee(user?._id?.$oid)}
                >
                  <SelectInitials
                    style={{
                      backgroundColor: `${COLORS_ARRAY[String(index).slice(-1)]}`,
                    }}
                  >
                    {user?.name?.charAt(0).toUpperCase() ?? ''}{' '}
                    {user?.surname?.charAt(0).toUpperCase() ?? ''}
                  </SelectInitials>
                  <FormattedMessage
                    id="messages.addNewTag.user_name"
                    defaultMessage="{name} {surname}"
                    values={{
                      name: user?.name,
                      surname: user?.surname,
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <SyncButton aria-label="sync-button" onClick={handleSync}>
            <SyncOutlinedIcon />
          </SyncButton>
          <ArrowsButton aria-label="close-fullscreen" onClick={handleCloseFullscreen}>
            <StyledImage src="/img/icon_close_fullscreen.svg" />
          </ArrowsButton>
          <MoreButton
            variant={'outlined'}
            onClick={handleFoldersMenuOpen}
            aria-label="folders"
            aria-controls="folders-menu"
            aria-haspopup="true"
          >
            <MoreVertOutlinedIcon />
          </MoreButton>
          <Menu
            id="folders-menu"
            anchorEl={anchorElFolders}
            open={Boolean(anchorElFolders)}
            onClose={handleFoldersMenuClose}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                width: 150,
              },
            }}
          >
            <SubMenu
              setAnchorElFolders={setAnchorElFolders}
              handleFollowUpMenuSelection={handleFollowUpMenuSelection}
            ></SubMenu>
            <StyledMenuItem onClick={handleEscalateMenuSelection}>
              <FormattedMessage id="messages.chat.menu.escalate" />
            </StyledMenuItem>
            <StyledMenuItem onClick={() => handleCloseMenu()}>
              <FormattedMessage id="messages.chat.menu.closed" />
            </StyledMenuItem>
            <StyledMenuItem onClick={() => handleCreateReportMenu()}>
              <FormattedMessage id="messages.chat.menu.create_report" />
            </StyledMenuItem>
            {retrieve.json('currentAccount').type === 'owner' ||
            retrieve.json('currentAccount').type === 'manager' ? (
              <StyledMenuItem onClick={() => handleCreateFeedbackMenu()}>
                <FormattedMessage id="messages.chat.menu.create_feedback" />
              </StyledMenuItem>
            ) : null}
          </Menu>
        </HeaderContainer>
        {showResponses ? (
          <AutoResponses
            data={responsesData}
            handleMessageSend={handleMessageSend}
            onClose={() => setShowResponses(false)}
          />
        ) : (
          <MessagesContainer
            onClick={() => {
              togglePanels('');
            }}
          >
            <Messages
              activeChatIsCustomer={activeChatIsCustomer}
              messages={messages}
              currentUserId={currentUserId}
              resend={resend}
              handleImagePopup={handleImagePopup}
              handleVideoPopup={handleVideoPopup}
              customerDataStoreTimezone={customerDataStoreTimezone}
              getMoreMessages={getMoreMessages}
              totalMessages={totalMessages}
              activeStoreId={activeStore?._id?.$oid}
              isFeedbackForm={false}
            />
            <MessagesEnd ref={messagesEndRef} />
          </MessagesContainer>
        )}
        <SendContainer>
          <CurrentOrder>
            <CurrentOrderName>
              <span>
                <FormattedMessage
                  id="messages.chat.current_order"
                  defaultMessage="# Current order"
                />
              </span>
              <IconButton aria-label="responses">
                <ExpandLessIcon fontSize="small" />
              </IconButton>
            </CurrentOrderName>
          </CurrentOrder>
          <ResponsesContainer>
            <ResponsesIcon
              color={showResponses ? 'primary' : 'default'}
              aria-label="responses"
              onClick={() => setShowResponses(!showResponses)}
            >
              <FlashOnIcon fontSize="small" />
            </ResponsesIcon>
            <Responses>
              <ResponsesContent>
                {responsesData?.map((item, index) => (
                  <StyledFastResponseButton
                    key={`response-button-${index}`}
                    variant="outlined"
                    disableElevation
                    onClick={handleFastResponseSend}
                  >
                    <FormattedMessage
                      id="messages.chat.button.label"
                      defaultMessage="{label}"
                      values={{
                        label: item.title,
                      }}
                    />
                  </StyledFastResponseButton>
                ))}
              </ResponsesContent>
            </Responses>
            <ResponsesIcon
              color={showResponses ? 'primary' : 'default'}
              aria-label="responses"
              onClick={() => togglePanels('')}
            >
              <MoreVertOutlinedIcon fontSize="small" />
            </ResponsesIcon>
          </ResponsesContainer>
          <SendInputs>
            <Buttons key={`file-uploder-${Math.random()}`}>
              <IconButton aria-label="surveys" onClick={handleSurveysSelectOpen}>
                <Image src="/img/icon_surveys.svg" />
              </IconButton>
              {/* <StyledFileUpload
                ref={fileInputRef}
                id="file-upload"
                type="file"
                onChange={handleImageUpload}
              /> */}
              <FilePicker
                onChange={(FileObject) => handleImageUpload(FileObject)}
                onError={(errMsg) => console.warn({ errMsg })}
              >
                <IconButton aria-label="images">
                  <AttachFileIcon fontSize="small" />
                </IconButton>
              </FilePicker>
              {/* <UploadIcon aria-label="attach file">
                <label htmlFor="file-upload">
                  <AttachFileIcon fontSize="small" />
                </label>
                <StyledFileUpload
                  ref={fileInputRef}
                  id="file-upload"
                  type="file"
                  onChange={handleImageUpload}
                />
              </UploadIcon> */}
            </Buttons>
            <SendMessage handleMessageSend={handleMessageSend} />
          </SendInputs>
        </SendContainer>
        <CopyRight>Copyright © 2022 Omcom Pte. Ltd. All Rights Reserved</CopyRight>
      </Container>
      {activePanel || width > 768 ? (
        <PanelCustomer
          getInbox={getInbox}
          activePanel={activePanel}
          activeContent={activeContent}
          togglePanels={togglePanels}
          users={users}
          isPreview={isPreview}
          setIsPreview={setIsPreview}
          setIsPreviewTicket={setIsPreviewTicket}
        />
      ) : null}
    </>
  );
};

export default React.memo(Chat);
