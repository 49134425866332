import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useIntl } from 'react-intl';
import { getInputDependedOptions, getInputDependedValues } from '../../../modules/getinput';
import { logMessage } from '../../../logger';

export const FormAutocompleteMultiple = ({ input, requiredFields, inputValues, setInputValues }) => {
  const intl = useIntl();
  const [values, setValues] = useState([]);
  const initialStateOptions = getInputDependedOptions(input, inputValues)?.map((option) => {
      let translation = option;
      try {
        translation = intl.formatMessage({
          id: String(option),
          defaultMessage: String(option),
        });
      } catch (e) {
        logMessage('e', e);
      }
      return {
        value: option,
        label: String(
          translation,
        )
      }
    },
  );
  const [options,] = useState(initialStateOptions);

  useEffect(() => {
    let dependedValues = getInputDependedValues(input, inputValues);
    dependedValues = dependedValues?.map((option) => {
        let translation = option;
        try {
          translation = intl.formatMessage({
            id: String(option),
            defaultMessage: String(option),
          });
        } catch (e) {
          logMessage('e', e);
        }
        return typeof option === 'string' ? {
          value: option,
          label: String(translation)
        } : option
      },
    );

    setValues(dependedValues?.map((inputValue) => {
      return initialStateOptions.find(item => item.value === (inputValue?.value ?? inputValue ?? ''));
    }));
  }, [input]);

  const handleChangeValues = (event, value) => {
    logMessage('event', event);
    value = value?.map((option) => {
        if (typeof option === 'string') {
          let translation = option;
          try {
            translation = intl.formatMessage({
              id: String(option),
              defaultMessage: String(option),
            });
          } catch (e) {
            logMessage('e', e);
          }
          return {
            value: option,
            label: String(translation)
          }
        } else {
          return option;
        }
      },
    );
    setValues(value);
    setInputValues({ ...inputValues, [input.id]: value });
  };

  let label = '';
  try {
    label = intl.formatMessage({ id: input?.label, defaultMessage: input?.label });
  } catch (e) {
  }

  return (
    <Autocomplete
      id={input.id}
      freeSolo
      multiple={true}
      value={values}
      options={options}
      onChange={handleChangeValues}
      getOptionLabel={(option) => option?.label ?? option ?? ''}
      renderInput={(params) => (
        <TextField {...params} required={requiredFields[input.id]} label={label} />
      )}
    />
  );
};
export default FormAutocompleteMultiple;
