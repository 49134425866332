import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Imitieren from './components/Imitieren';
import AccountDataContext from './context/AccountDataContext';
import FBAccountDataContext from './context/FBAccountDataContext';
import GlobalLoadingContext from './context/GlobalLoadingContext';
import store from './app/store';
import { Provider } from 'react-redux';
import JwtTokenContext from './context/JwtTokenContext';
import ActiveChatContext from './context/ActiveChatContext';
import GlobalHandlersContext from './context/GlobalHandlersContext';
import GlobalUpdateQueryContext from './context/GlobalUpdateQueryContext';
import { ThemeProvider } from '@mui/material';
import WampHandlersContext from './context/WampHandlersContext';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#006977',
    },
    secondary: {
      main: '#3099A7',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
      <GlobalLoadingContext>
        <FBAccountDataContext>
          <ThemeProvider theme={theme}>
            <JwtTokenContext>
              <AccountDataContext>
                <Imitieren />
                <WampHandlersContext>
                  <ActiveChatContext>
                    <GlobalUpdateQueryContext>
                      <GlobalHandlersContext>
                        <App />
                      </GlobalHandlersContext>
                    </GlobalUpdateQueryContext>
                  </ActiveChatContext>
                </WampHandlersContext>
              </AccountDataContext>
            </JwtTokenContext>
          </ThemeProvider>
        </FBAccountDataContext>
      </GlobalLoadingContext>
    </Provider>
  </>
);
