import { postData } from '../modules/fetch';
import { logMessage } from '../logger';

const postLogin = (requestBody, successCallback, errorCallback) => {
  return postData('/login', requestBody, false)
    .then((data) => {
      if (data) {
        logMessage(data);
        successCallback(data);
      }
    })
    .catch((err) => {
      console.error(err);
      errorCallback(err);
    });
}

export default postLogin;
