import { FormattedMessage, useIntl } from 'react-intl';
import {
  Buttons,
  ButtonSave,
  ButtonSaveTitleSmall,
  ButtonStyledListItem,
  ButtonTitle,
  ButtonTitleSmall,
  Container,
  InnerStyledList,
  InnerStyledListRow,
  StyledListItem,
  StyledListItemText,
  StyledSubListItemText,
  TimezoneBlock,
  TimezoneButtons,
  TimezoneStyledListItem,
  Title,
} from './style';
import { useContext, useEffect, useState } from 'react';
import { toTitleCase } from '../../modules/string';
import { fromNowSecondsZone } from '../../modules/renderDate';
import moment from 'moment/moment';
import { WampHandlersContext } from '../../context/WampHandlersContext';
import { JobsStateContext } from '../../context/JobsStateContext';
import { getData, getDataByUrl } from '../../modules/fetch';
import JobStateToString from '../JobState';
import { Box, Tab, Tabs } from '@mui/material';
import Button from '@mui/material/Button';
import getStoresConfig from '../../api/getStoresConfig';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useUpdateTimezoneMutation } from '../../api/storeAPI';
import { logMessage } from '../../logger';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StoreDetailsComponent = ({ object: store }) => {
  const intl = useIntl();
  const [jobsState, setJobsState] = useContext<any>(JobsStateContext);
  const [, setWampHandlers] = useContext<any>(WampHandlersContext);
  const [toJobState, setToJobState] = useState<any>({});
  const [authUrls, setAuthUrls] = useState<any>({});
  const [timezones, setTimezones] = useState<any>([]);
  const [offset, setOffset] = useState<any>(store?.basic?.timezone?.offset);
  const [timezone, setTimezone] = useState<string>();
  let wampIsSet = false;

  const [updateTimezone] = useUpdateTimezoneMutation();

  useEffect(() => {
    if (!wampIsSet) {
      setWampHandlers(wampHandlers);
      wampIsSet = true;
    }
  }, []);

  useEffect(() => {
    getStoresConfig((config) => {
      const connections = config[store?.basic?.provider];
      setAuthUrls(connections);
    });
    getData('/utility/timezones')
      .then((data) => {
        if (data)
          setTimezones(data.data);
      })
      .catch((err) => {
        console.error(err);
      });
    setTimezone(store?.basic?.timezone?.name ?? null);
    setOffset(store?.basic?.timezone?.offset ?? null);
  }, [store]);

  const setNewTimezone = async () => {
    if (timezone) {
      await updateTimezone({
        id: store?._id?.$oid,
        name: timezone,
      }).then((response: any) => {
        if (response?.data?.data) {
          store.basic.timezone = response?.data?.data;
          setTimezone(store?.basic?.timezone?.name ?? null);
          setOffset(store?.basic?.timezone?.offset ?? null);
        }
      });
    } else {
      alert(intl.formatMessage({
        id: 'storeDetails.timezone.not.specified',
        defaultMessage: 'Please choose timezone'
      }));
    }

    return true;
  };

  useEffect(() => {
    setJobsState({ ...jobsState, ...toJobState });
  }, [toJobState]);

  const wampHandlers = {
    job: (message: any) => {
      if (message?.job) {
        const job = message.job;
        const name = job?.name ?? 'unknown';
        const storeId = job?.storeId ?? null;
        let states = { [name]: {} };
        if (storeId !== null) {
          states[name][storeId] = job;
          setToJobState({ ...states });
        }
      }
    },
  };

  const fieldPreProcess = (field) => {
    if (field === 'ownerId') {
      return null;
    }
    return toTitleCase(field);
  }

  const ObjectTabs = ({ object: store }) => {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      logMessage('event', event);
      setValue(newValue);
    };

    const a11yProps = (index: number) => ({
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    });

    const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box>
              <div>{children}</div>
            </Box>
          )}
        </div>
      );
    };

    return (
      <>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          {Object.keys(store || {}).map((infoKey, i) => {
            return (() => {
              if (infoKey === '_id') return;
              let fieldTitle = fieldPreProcess(infoKey);
              if (fieldTitle && store?.[infoKey]) {
                const translatedFieldTitle = intl.formatMessage({
                  id: 'storeDetails.tabs.tab.title.' + infoKey,
                  defaultMessage: fieldTitle
                });
                return (<Tab label={translatedFieldTitle} {...a11yProps(0)} key={`inner-styled-list-${i}`} />)
              }
            })()
          })}
        </Tabs>

        {Object.keys(store || {}).map((infoKey, i) => {
            return (() => {
              if (infoKey === '_id') return;
              let fieldTitle = fieldPreProcess(infoKey);
              if (fieldTitle && store?.[infoKey]) {
                return (
                  <TabPanel value={value} index={i - 1} key={`inner-styled-list-${i}`}>
                    <ObjectFieldValue object={{ [infoKey]: store?.[infoKey] }} />
                  </TabPanel>
                );
              }

              return;
            })()
          }
        )}
      </>
    )
  }

  const ObjectFieldValue = ({ object: store, parent = '' }) => {

    const fieldValuePreProcess = (field, value) => {
      if (['ownerId', 'owner_id'].find((exclude) => exclude === field)) {
        return null;
      }

      if (String(field).toLowerCase().endsWith('time') && !isNaN(value)) {
        return fromNowSecondsZone(value) + ', ' + moment(value * 1000).toDate().toISOString().replace('T', ' ');
      }

      if (String(field).toLowerCase().endsWith('expires') && !isNaN(value)) {
        return '(' + value + ') ' + fromNowSecondsZone((Date.now() + (value * 1000)) / 1000) + ', ' + moment(Date.now() + (value * 1000)).toDate().toISOString().replace('T', ' ');
      }

      if (value?.date) {
        return value?.date;
      }

      if (value?.$oid) {
        return value?.$oid;
      }

      return value;
    }

    return (
      <>
        {Object.keys(store || {}).map((infoKey, i) => {
            return (() => {
              if (infoKey === '_id') return;
              let fieldTitle = fieldPreProcess(infoKey);
              if (fieldTitle && store?.[infoKey]) {
                const translatedFieldTitle = intl.formatMessage({
                  id: 'storeDetails.tabs.tabpanel.label.' + infoKey,
                  defaultMessage: fieldTitle
                });
                return (
                  <InnerStyledList key={`inner-styled-list-${i}`}>
                    <Title key={`store-field-value-${parent}-${i}`}>{translatedFieldTitle}</Title>
                    {Object.keys(store?.[infoKey] || {}).map((field, j) => (
                      <StyledListItem key={`store-field-value-${parent}-${field}-${i}-${j}`}>
                        {(() => {
                          let fieldTitle = fieldPreProcess(field);

                          let value = fieldValuePreProcess(field, store?.[infoKey]?.[field]);
                          if (value === null) {
                            return;
                          }

                          if (typeof value === 'object') {
                            return (<ObjectFieldValue object={{ [field]: value }} />);
                          }

                          return (
                            <StyledListItemText>
                              <div>{fieldTitle}</div>
                              <div>{value || 'n/a'}</div>
                            </StyledListItemText>
                          );
                        })()}
                      </StyledListItem>
                    ))}
                  </InnerStyledList>
                );
              }

              return;
            })()
          }
        )}
      </>
    )
  }

  const jnProducts = 'store_products_re_sync';
  const jnOrders = 'store_orders_re_sync';
  const jnChats = 'store_customers_re_sync';

  // const updateJobsState = storeId => {
  //   setTimeout(async () => {
  //     const retrievedJobsState = { ...(await getJobState(storeId)) };
  //     setJobsState({ ...jobsState, ...retrievedJobsState });
  //   });
  // };

  const runProducts = (storeId) => () => {
    getData(`/stores/resync/products/${storeId}`);

    return true;
  };

  const runOrders = (storeId) => () => {
    getData(`/stores/resync/orders/${storeId}`);

    return true;
  };

  const runChats = (storeId) => () => {
    getData(`/stores/resync/chats/${storeId}`);

    return true;
  };

  const storeId = store?._id?.$oid;

  const disabledProducts = !(!jobsState?.[jnProducts] || !jobsState?.[jnProducts]?.[storeId] || jobsState?.[jnProducts]?.[storeId]?.state === 'finished');
  const disabledOrders = !(!jobsState?.[jnOrders] || !jobsState?.[jnOrders]?.[storeId] || jobsState?.[jnOrders]?.[storeId]?.state === 'finished');
  const disabledChats = !(!jobsState?.[jnChats] || !jobsState?.[jnChats]?.[storeId] || jobsState?.[jnChats]?.[storeId]?.state === 'finished');

  const handleRedirect = (storeAuthUrl) => {
    getDataByUrl(storeAuthUrl)
      .then((data) => {
        window.location = data.data.redirect;
      })
      .catch((err) => {
        console.error(err);
      });

    return true;
  };

  const Timezones = ({ timezones, offset }) => (
    <TimezoneButtons>
      <TimezoneBlock>
        <TimezoneStyledListItem>
          <Typography fontSize={'large'}>
            <FormattedMessage id="storeDetails.timezone.label" defaultMessage="Timezone" />
          </Typography>
          <Autocomplete
            disablePortal
            value={timezone}
            onChange={(event: any, newTimezone: string | null) => {
              logMessage('event, newTimezone', event, newTimezone);
              setTimezone(newTimezone);
            }}
            id="combo-box-timezones"
            options={timezones}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} />}
          />
        </TimezoneStyledListItem>
        <TimezoneStyledListItem>
          <Typography fontSize={'large'}>
            <FormattedMessage id="storeDetails.timezone.offset.label" defaultMessage="Offset" />
          </Typography>
          <TextField
            sx={{ width: 300 }}
            aria-readonly={true}
            InputProps={{
              readOnly: true,
            }}
            value={offset ?? ''}
          />
        </TimezoneStyledListItem>
      </TimezoneBlock>
      <TimezoneBlock>
        <ButtonSaveTitleSmall>
          <ButtonSave
            onClick={setNewTimezone}
          >
            <FormattedMessage id="storeDetails.timezone.button.save.label" defaultMessage="Update" />
          </ButtonSave>
        </ButtonSaveTitleSmall>
      </TimezoneBlock>
    </TimezoneButtons>
  );

  return (
    <Container>
      <>
        <Timezones timezones={timezones} offset={offset} />
        {store?.basic?.provider !== 'whatsapp' && (
          <>
            <Buttons>
              <InnerStyledListRow>
                {authUrls && Object.keys(authUrls || {}).map((app, i) => {
                  return (
                    <ButtonTitleSmall key={`button_title-handleRedirect-${i}`}>
                      <Button
                        onClick={() => handleRedirect(authUrls[app]?.auth_url)}
                        color="secondary">
                        <FormattedMessage id={`storeDetails.button.reconnect.${authUrls[app]?.app_id}`} defaultMessage="Reconnect {name}"
                                          values={{ name: toTitleCase(authUrls[app]?.name) }} />
                      </Button>
                    </ButtonTitleSmall>
                  )
                })}
              </InnerStyledListRow>
            </Buttons>
            <Buttons>
              <InnerStyledList>
                <ButtonTitle>
                  <Button
                    onClick={runProducts(storeId)}
                    disabled={disabledProducts}
                    color="secondary"
                  >
                    <FormattedMessage id="storeDetails.button.sync.products" defaultMessage="Syncronize products" />
                  </Button>
                </ButtonTitle>
                <ButtonStyledListItem>
                  <StyledListItemText>
                    <FormattedMessage id="storeDetails.button.sync.status" defaultMessage="Status:" />
                    <div>{JobStateToString(jobsState, jnProducts, storeId, intl) ?? 'N/A'}</div>
                  </StyledListItemText>
                </ButtonStyledListItem>
              </InnerStyledList>
              <InnerStyledList>
                <ButtonTitle>
                  <Button
                    onClick={runOrders(storeId)}
                    disabled={disabledOrders}
                    color="secondary"
                  >
                    <FormattedMessage id="storeDetails.button.sync.orders" defaultMessage="Syncronize orders" />
                  </Button>
                </ButtonTitle>
                <ButtonStyledListItem>
                  <StyledListItemText>
                    <FormattedMessage id="storeDetails.button.sync.status" defaultMessage="Status:" />
                    <div>{JobStateToString(jobsState, jnOrders, storeId, intl) ?? 'N/A'}</div>
                  </StyledListItemText>
                </ButtonStyledListItem>
              </InnerStyledList>
              <InnerStyledList>
                <ButtonTitle>
                  <Button
                    onClick={runChats(storeId)}
                    disabled={disabledChats}
                    color="secondary"
                  >
                    <FormattedMessage id="storeDetails.button.sync.chats" defaultMessage="Syncronize chats" />
                  </Button>
                </ButtonTitle>
                <ButtonStyledListItem>
                  <StyledListItemText>
                    <FormattedMessage id="storeDetails.button.sync.status" defaultMessage="Status:" />
                    <StyledSubListItemText>
                      <div>{JobStateToString(jobsState, jnChats, storeId, intl) ?? 'N/A'}</div>
                    </StyledSubListItemText>
                  </StyledListItemText>
                </ButtonStyledListItem>
              </InnerStyledList>
            </Buttons>
          </>)}

        <ObjectTabs object={store} />

      </>
    </Container>
  );
};

export default StoreDetailsComponent;
