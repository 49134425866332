import { getData } from '../modules/fetch';

const getMetrics = (callback, { page, pp, period = 'total' }) => {
  getData(`/reporting/tracker/metric/listings/${page},${pp}/${period}`)
    .then((data) => {
      if (data) {
        const values = Object.values(data.data) as any;
        const meta = data.meta as any;
        callback(values, meta);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export default getMetrics;
