import TextField from '@mui/material/TextField';
import { useIntl } from 'react-intl';
import { getInputDependedLabel, getInputDependedValues } from '../../../modules/getinput';

export const FormTextarea = ({ input, inputValues, requiredFields, handleChange }) => {
  const intl = useIntl();
  return (
    <TextField
      id={input.id}
      required={requiredFields[input.id]}
      label={intl.formatMessage({
        id: getInputDependedLabel(input, inputValues),
        defaultMessage: getInputDependedLabel(input, inputValues),
      })}
      value={getInputDependedValues(input, inputValues)}
      type="text"
      variant="outlined"
      autoComplete="none"
      multiline
      rows={4}
      disabled={!!input.disabled}
      onChange={(event) => handleChange(input.id)(event.target.value)}
    />
  );
};
export default FormTextarea;
