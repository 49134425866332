import { configureStore } from '@reduxjs/toolkit';
import { userApi } from '../api/userAPI';
import { storeApi } from '../api/storeAPI';
import { accountApi } from '../api/accountAPI';
import { surveyApi } from '../api/surveyAPI';
import { autoReplyApi } from '../api/autoReplyAPI';
import { reportApi } from '../api/reportAPI';
import { feedbackApi } from '../api/feedbackAPI';
import { contactApi } from '../api/contactsAPI';
import { broadcastApi } from '../api/broadcastAPI';

export default configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [storeApi.reducerPath]: storeApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [surveyApi.reducerPath]: surveyApi.reducer,
    [autoReplyApi.reducerPath]: autoReplyApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [feedbackApi.reducerPath]: feedbackApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [broadcastApi.reducerPath]: broadcastApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(storeApi.middleware)
      .concat(accountApi.middleware)
      .concat(surveyApi.middleware)
      .concat(autoReplyApi.middleware)
      .concat(contactApi.middleware)
      .concat(broadcastApi.middleware)
      .concat(reportApi.middleware)
      .concat(feedbackApi.middleware),
});
