import { createContext, useEffect, useState } from 'react';
import { getData } from '../modules/fetch';

export const JobsStateContext = createContext(null);

export const getJobsState = async () => {
  let states = {};
  await getData('/job/states')
    .then((data) => {
      if (data && data.data.length > 0) {
        data.data.map((state) => {
          const name = state?.name ?? 'unknown';
          if (!(name in states)) {
            states[name] = {};
          }
          states[name][state.storeId] = state;
        });
      }
    })
    .catch((err) => {
      console.error(err);
    });

  return states;
};

export const getJobState = async (name, storeId = null) => {
  let state = {};
  await getData(`/job/state/${name}` + (storeId ? `?storeId=${storeId}` : ''))
    .then((data) => {
      if (data) {
        const name = data?.data?.name ?? 'unknown';
        if (!(name in state)) {
          state[name] = {};
        }
        state[name][data?.data?.storeId ?? storeId] = data.data;
      }
    })
    .catch((err) => {
      console.error(err);
    });

  return state;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [jobsState, setJobsState] = useState({});

  useEffect(() => {
    setTimeout(async () => {
      const retrievedJobsState = { ...(await getJobsState()) };
      setJobsState({ ...jobsState, ...retrievedJobsState });
    });
  }, []);

  return (
    <JobsStateContext.Provider value={[jobsState, setJobsState]}>
      {children}
    </JobsStateContext.Provider>
  );
};
