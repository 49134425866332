import { FormattedMessage } from 'react-intl';
import { Container, StyledList } from './styles';
import { useContext, useEffect, useState } from 'react';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import Timestamp from '../Timestamp';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { logMessage } from '../../logger';
import { MessagesPropsContext } from '../../context/MessagesPropsContext';
import { useFeedbacksQuery, useFetchTemplateFeedbackQuery } from '../../api/feedbackAPI';
import { setValuesFormTemplate } from '../../api/setValuesFormTemplate';
import { standardJsonResponseSync } from '../../modules/fetch';

const FeedbacksList = () => {
  const [activeChat] = useContext(ActiveChatContext);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [, , , setShowFeedbackFormPopup, , setSelectedFeedbackForm, , setIsUpdate] =
    useContext(MessagesPropsContext);
  const {
    data: templateFeedback,
    error: errorTemplate,
    isError: isErrorTemplate,
  } = useFetchTemplateFeedbackQuery(activeChat?._id?.$oid);
  const { data: feedbacksList, error: errorFetch } = useFeedbacksQuery(activeChat?._id?.$oid);

  useEffect(() => {
    if (isErrorTemplate) {
      logMessage(
        'result',
        // @ts-ignore
        errorTemplate.data,
        // @ts-ignore
        { json: () => errorTemplate.data },
        // @ts-ignore
        { status: errorTemplate.status, json: () => errorTemplate.data },
      );
      // @ts-ignore
      let response = { ...errorTemplate };
      let result;
      try {
        result = standardJsonResponseSync(response);
      } catch (ResponseError) {
        console.warn('ResponseError', ResponseError);
      }

      logMessage('result', result);
    }
  }, [errorTemplate, isErrorTemplate]);

  const handleListItemClick = (event, index) => {
    logMessage(event);
    setSelectedIndex(index);
    const found = { ...feedbacksList?.data?.[index], isEditable: true };
    const temp = setValuesFormTemplate(templateFeedback?.data, found);
    setSelectedFeedbackForm(temp);
    setShowFeedbackFormPopup(true);
    setIsUpdate(true);
  };

  return (
    <Container>
      <h4>
        <FormattedMessage id="messages.feedbacksList.title" defaultMessage="Feedbacks Form List" />
      </h4>
      <StyledList>
        {!isErrorTemplate &&
        !errorFetch &&
        Array.isArray(feedbacksList?.data) &&
        feedbacksList?.data.length > 0 ? (
          feedbacksList.data.map((item, index) => (
            <ListItemButton
              key={`ticket-${index}`}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
            >
              <ListItemText>
                {index + 1}.{' '}
                <Timestamp
                  showDate={item.createdAt.date}
                  customerDataStoreTimezone={item.store?.timezone}
                />{' '}
                {item.customer.assigneeName}
              </ListItemText>
            </ListItemButton>
          ))
        ) : (
          <h4>
            <FormattedMessage
              id="messages.feedbacksList.no_feedbacks"
              defaultMessage="No Feedback Forms"
            />
          </h4>
        )}
      </StyledList>
    </Container>
  );
};
export default FeedbacksList;
