import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, Navigate } from 'react-router-dom';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import KeyboardTabOutlinedIcon from '@mui/icons-material/KeyboardTabOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import CellTowerIcon from '@mui/icons-material/CellTower';
import { FormattedMessage } from 'react-intl';
import { mediaQueries, MobileOnly } from '../../constants/breakpoints';
import Popup from '../../components/Popup';
import Button from '@mui/material/Button';
import { clear } from '../../modules/storage';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import { AccountDataContext } from '../../context/AccountDataContext';
import { NavContext } from '../../context/NavContext';
import { InboxCountsContext } from '../../context/InboxCountsContext';
import Logo from '../Logo';
import { StyledImage } from '../../containers/Chat/styles';
import { Badge } from '@mui/material';
import { useStoresQuery } from '../../api/storeAPI';

const Wrap = styled.div<{ $isActive?: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 61px;
  left: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 1;
  @media ${mediaQueries.mediumDesktop} {
    display: none;
  }
  ${(props) =>
    props.$isActive
      ? `
      display:fixed;
    `
      : `
      display:none;
    `}
`;
const Container = styled.div<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: #ecf3f9;
  position: fixed;
  width: 208px;
  min-width: 208px;
  height: calc(100% - 61px);
  top: 61px;
  justify-content: space-between;
  z-index: 2;
  padding-top: 30px;
  transform: translateX(-208px);
  transition: transform 0.2s;
  ${(props) =>
    props.$isActive
      ? `
      transform: translateX(0);
    `
      : ''} @media ${mediaQueries.mediumDesktop} {
    height: 100%;
    overflow-y: auto;
    position: relative;
    transform: translateX(0);
    top: 0;
  }
  @media ${mediaQueries.mobile} {
    overflow-y: auto;
  }
`;

const Links = styled.div`
  flex-direction: column;
  width: 100%;
`;
const LogoLink = styled(Link)`
  display: block;
  margin: 0 auto 15px;
  width: 100%;
  text-align: center;
`;

const StyledLink = styled(Link)<{ $isActive?: boolean; to?: string }>`
  display: flex;
  align-items: center;
  color: #334d6e;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s;
  margin: 0 28px 10px 16px;
  border-radius: 50px;
  padding: 6px 12px;

  svg {
    color: #334d6e;
    font-size: 24px;
  }

  span {
    margin-left: 10px;
  }

  :hover {
    text-decoration: none;
    background-color: #bde5eb;
  }

  ${(props) =>
    props.$isActive
      ? `
    background-color: #BDE5EB;
  `
      : ''}
  span {
    display: inline-block;
  }
`;

const StyledSubLink = styled(StyledLink)`
  margin-left: 50px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  button {
    min-width: 48%;
    margin-bottom: 30px;
  }
`;

const Title = styled.h3`
  text-align: center;
  color: #334d6e;
`;

const navLinks = [
  {
    title: 'main.nav.inbox',
    url: '/messages',
    id: 'messages',
    icon: <MailOutlineOutlinedIcon />,
    viewLevel: 4,
    subLinks: [
      {
        title: 'main.nav.inbox.open',
        url: '/open',
        id: 'open',
      },
      {
        title: 'main.nav.inbox.followup',
        url: '/followup',
        id: 'followup',
      },
      {
        title: 'main.nav.inbox.escalated',
        url: '/escalated',
        id: 'escalated',
      },
      {
        title: 'main.nav.inbox.closed',
        url: '/resolved',
        id: 'resolved',
      },
    ],
  },
  {
    title: 'main.nav.dashboard',
    url: '/dashboard',
    id: 'dashboard',
    icon: <DashboardOutlinedIcon />,
    viewLevel: 4,
    subLinks: [
      {
        title: 'main.nav.reports',
        url: '/',
        id: 'reports',
        viewLevel: 4,
        roles: ['MODULE_REPORT'],
      },
      {
        title: 'main.nav.chat_statistics',
        url: '/chat-statistics',
        id: 'chat-statistics',
        viewLevel: 4,
        roles: ['MODULE_STATISTIC'],
      },
    ],
  },
  {
    title: 'main.nav.orders',
    url: '/orders',
    id: 'customers',
    icon: <UnarchiveOutlinedIcon />,
    viewLevel: 3,
  },
  // {
  //   title: 'main.nav.analytics',
  //   url: '/analytics',
  //   id: 'analytics',
  //   icon: <BarChartIcon />,
  //   viewLevel: 3,
  // },
  {
    title: 'main.nav.user_management',
    url: '/users',
    id: 'users',
    icon: <PersonOutlinedIcon />,
    viewLevel: 3,
    roles: ['MODULE_USER_MANAGEMENT'],
  },
  {
    title: 'main.nav.surveys',
    url: '/surveys',
    id: 'surveys',
    icon: <FactCheckOutlinedIcon />,
    viewLevel: 3,
    roles: ['MODULE_SURVEY'],
  },
  {
    title: 'main.nav.store_management',
    url: '/stores',
    id: 'stores',
    iconContentFrom: 'disconnectedStores',
    iconTemplate: (badgeContent) => (
      <Badge badgeContent={badgeContent} color="error" style={{ marginLeft: 0 }}>
        <AccountBalanceOutlinedIcon />
      </Badge>
    ),
    viewLevel: 3,
    roles: ['MODULE_STORE_MANAGEMENT'],
  },
  {
    title: 'main.nav.templates_management',
    url: '/templates',
    id: 'templates',
    icon: <ArticleOutlinedIcon />,
    viewLevel: 3,
    roles: ['MODULE_TEMPLATES_MANAGEMENT'],
  },
  {
    title: 'main.nav.contacts',
    url: '/contacts',
    id: 'contacts',
    icon: <StyledImage src="/img/icon_contacts.svg" />,
    viewLevel: 3,
    roles: ['MODULE_CONTACTS_MANAGEMENT'],
  },
  {
    title: 'main.nav.broadcast',
    url: '/broadcast',
    id: 'broadcast',
    icon: <CellTowerIcon />,
    viewLevel: 3,
    roles: ['MODULE_BROADCAST_MANAGEMENT'],
  },
  {
    title: 'main.nav.settings',
    url: '/settings',
    id: 'settings',
    icon: <SettingsOutlinedIcon />,
    viewLevel: 4,
    subLinks: [
      {
        title: 'main.nav.settings.general',
        url: '/general',
        id: 'general',
      },
      // {
      //   title: 'main.nav.settings.subscription',
      //   url: '/subscription',
      //   id: 'subscription',
      // },
      {
        title: 'main.nav.settings.billing',
        url: '/billing',
        id: 'billing',
      },
      {
        title: 'main.nav.settings.out_of_office',
        url: '/out-of-office',
        id: 'out-of-office',
        roles: ['MODULE_OUT_OF_OFFICE'],
      },
      {
        title: 'main.nav.settings.integrations',
        url: '/integrations',
        id: 'integrations',
      },
      {
        title: 'main.nav.settings.api_key',
        url: '/api-key',
        id: 'api-key',
      },
    ],
  },
];

const logoutLink = {
  title: 'main.nav.logout',
  url: window.location.pathname,
  id: 'logout',
  icon: <KeyboardTabOutlinedIcon />,
};

const Nav = ({ currentStep = null, subStep = null, ...props }) => {
  const { data: stores } = useStoresQuery(true);
  const [navigationLinks, setNavigationLinks] = useState(navLinks);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [currentAccount] = useContext<any>(AccountDataContext);
  const [, setActiveChat] = useContext<any>(ActiveChatContext);
  const [isNavVisible, setNavVisible] = useContext<any>(NavContext);
  const [counts] = useContext(InboxCountsContext);

  const [success, setSuccess] = useState(false);

  const getIconContent = useCallback(
    (from) => {
      if (from === 'disconnectedStores') {
        return stores?.meta?.disconnected || 0;
      }
      return null;
    },
    [stores],
  );
  useEffect(() => {
    if (currentAccount) {
      setNavigationLinks([...navLinks]);
    }
  }, [currentAccount]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setActiveChat(null);
    clear();
    setSuccess(true);
  };
  const closeNavPanel = () => {
    if (isNavVisible) {
      setNavVisible(false);
    }
  };

  function isAllowed(link: any) {
    const viewLevelAllowed =
      !currentAccount?.viewsLevel ||
      !link?.viewLevel ||
      currentAccount?.viewsLevel <= link?.viewLevel;
    let rolesAllowed = false;
    if (link?.roles) {
      if (currentAccount?.roles) {
        const intersect = currentAccount?.roles?.filter((x) => link?.roles?.includes(x));
        rolesAllowed = intersect?.length === link?.roles?.length;
      }
    } else {
      rolesAllowed = true;
    }
    return viewLevelAllowed && rolesAllowed;
  }

  return (
    <>
      {success ? (
        <Navigate to="/sign-in" />
      ) : (
        <>
          {showLogoutPopup && (
            <Popup
              isPreviewTicket={false}
              setIsPreviewTicket={() => {}}
              handleClose={() => setShowLogoutPopup(false)}
            >
              <>
                <Title>
                  <FormattedMessage
                    id="main.nav.title.logout"
                    defaultMessage="Are you sure, you want to Sign out?"
                  />
                </Title>
                <form autoComplete="off" onSubmit={handleFormSubmit}>
                  <ButtonsContainer>
                    <Button size="large" variant="contained" color="primary" type="submit">
                      <FormattedMessage id="main.nav.button.logout" defaultMessage="Sign out" />
                    </Button>
                  </ButtonsContainer>
                </form>
              </>
            </Popup>
          )}
          <Wrap $isActive={isNavVisible} onClick={closeNavPanel} />
          <Container $isActive={isNavVisible} {...props}>
            <Links>
              <>
                <MobileOnly>
                  <LogoLink to="/messages">
                    <Logo />
                  </LogoLink>
                </MobileOnly>
                {navigationLinks?.map((link, index) => {
                  return isAllowed(link) &&
                    (link.id !== 'customers' ||
                      (link.id === 'customers' && currentAccount.email === 'demo@omcom.io')) ? (
                    <React.Fragment key={`nav-link-top-${index}`}>
                      {link?.url ? (
                        <StyledLink
                          to={link.url}
                          $isActive={link.id === currentStep && !subStep}
                          key={`nav-link-top-${index}`}
                          onClick={() => setNavVisible(!isNavVisible)}
                        >
                          {link.iconContentFrom
                            ? link.iconTemplate(getIconContent(link.iconContentFrom))
                            : link.icon}
                          <span>
                            <FormattedMessage id={link.title} defaultMessage={link.id} />
                          </span>
                        </StyledLink>
                      ) : (
                        <StyledLink
                          to={'#'}
                          $isActive={link.id === currentStep && !subStep}
                          key={`nav-link-top-${index}`}
                          onClick={() => setNavVisible(!isNavVisible)}
                        >
                          {link.iconContentFrom
                            ? link.iconTemplate(getIconContent(link.iconContentFrom))
                            : link.icon}
                          <span>
                            <FormattedMessage id={link.title} defaultMessage={link.id} />
                          </span>
                        </StyledLink>
                      )}
                      {link.id === currentStep && (
                        <>
                          {link.subLinks?.map((subLink, subindex) => {
                            return (
                              isAllowed(subLink) && (
                                <StyledSubLink
                                  to={`${link.url}${subLink.url}`}
                                  $isActive={subLink.id === subStep}
                                  key={`nav-link-top-${index}-${subindex}`}
                                  onClick={() => setNavVisible(!isNavVisible)}
                                >
                                  <FormattedMessage
                                    id={subLink.title}
                                    defaultMessage={subLink.id}
                                  />
                                  {link.id === 'messages' && <span>{counts[subLink.id]}</span>}
                                </StyledSubLink>
                              )
                            );
                          })}
                        </>
                      )}
                    </React.Fragment>
                  ) : null;
                })}
              </>
            </Links>
            <Links>
              <StyledLink to={window.location.pathname} onClick={() => setShowLogoutPopup(true)}>
                {logoutLink.icon}
                <span>
                  <FormattedMessage id={logoutLink.title} defaultMessage={logoutLink.id} />
                </span>
              </StyledLink>
            </Links>
          </Container>
        </>
      )}
    </>
  );
};

export default Nav;
