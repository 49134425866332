import styled from 'styled-components';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Inventory2Outlined from '@mui/icons-material/Inventory2Outlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import { mediaQueries } from '../../constants/breakpoints';
import { Badge } from '@mui/material';

export const Container = styled.div`
  position: fixed;
  top: 61px;
  right: 0;
  width: 51px;
  height: calc(100% - 172px);
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: start;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  @media ${mediaQueries.desktop} {
    position: relative;
    top: 0;
    height: 100%;
    min-width: 51px;
    margin-left: auto;
  }
  @media ${mediaQueries.mobile} {
    box-shadow: 0px 0px 0px 0px;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
`;
export const NavContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 51px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ecf3f9;
  z-index: 5;
  @media ${mediaQueries.mobile} {
    top: 1px;
    min-width: 49px;
  }
`;
export const AddNewIcon = styled(PersonOutlineOutlinedIcon)`
  width: 20px;
  height: 20px;
  margin-top: 40px;
  cursor: pointer;
`;
export const OrderIcon = styled(AssignmentOutlinedIcon)`
  width: 20px;
  height: 20px;
  margin-top: 40px;
  cursor: pointer;
`;
export const ProductIcon = styled(Inventory2Outlined)`
  width: 20px;
  height: 20px;
  margin-top: 40px;
  cursor: pointer;
`;
export const TicketIcon = styled(PlaylistAddCheckOutlinedIcon)`
  width: 20px;
  height: 20px;
  margin-top: 40px;
  cursor: pointer;
`;

export const CommentIcon = styled(CommentOutlinedIcon)`
  width: 20px;
  height: 20px;
  margin-top: 40px;
  cursor: pointer;
`;

export const CommentWithBadgikIcon = styled(CommentOutlinedIcon)`
  width: 20px;
  height: 20px;
  margin-top: 0;
  cursor: pointer;
`;
export const Badgik = styled(Badge)`
  top: 40px;
`;

export const WorkHistoryIcon = styled(WorkHistoryOutlinedIcon)`
  width: 20px;
  height: 20px;
  margin-top: 40px;
  cursor: pointer;
`;

export const FeedbackIcon = styled(RateReviewOutlinedIcon)`
  width: 20px;
  height: 20px;
  margin-top: 40px;
  cursor: pointer;
`;
