export const processRow = (data, inputValues, requiredFields, copyableDefaults) => (row) => {
  if (row?.children) {
    if ('rows' in row?.children) {
      if (row.type === 'copyable') {
        inputValues[row.id] = [];
        requiredFields[row.id] = [];
        row.children.rows.map(
          processRow(row, inputValues[row.id], requiredFields[row.id], copyableDefaults),
        );
        if (row.default) {
          inputValues[row.id].push(row.default);
        } else {
          copyableDefaults &&
            inputValues[row.id].push(copyableDefaults(row, inputValues, requiredFields));
        }
      } else {
        return row.children.rows.map((innerRow) => {
          return innerRow.children.map((input) => {
            requiredFields[input.id] = input.required ?? false;
            return (inputValues[input.id] = data[input.id] ?? input.defaultValue ?? null);
          });
        });
      }
    } else {
      return row.children.map((input) => {
        requiredFields[input.id] = input.required ?? false;
        if (input.type === 'messages') {
          return (inputValues[input.id] = input.options ?? null);
        }
        return (inputValues[input.id] = data[input.id] ?? input.defaultValue ?? null);
      });
    }
  } else {
    return row.children.map((input) => {
      requiredFields[input.id] = input.required ?? false;
      return (inputValues[input.id] = data[input.id] ?? input.defaultValue ?? null);
    });
  }
};

export const setValuesFormTemplate = (template, data = null, copyableDefaults = null): any => {
  let inputValues = {};
  let requiredFields = {};
  Object.values(template)?.map((step: any) => {
    return step.form?.rows.map(processRow(template, inputValues, requiredFields, copyableDefaults));
  });

  if (data && 'isEditable' in data) {
    inputValues = { ...inputValues, ...data };
  }

  return { inputValues, requiredFields };
};
