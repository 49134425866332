import {
  ProductID,
  ProductList,
  ProductListBody,
  ProductListInfo,
  ProductListPrice,
  ProductListSKU,
  ProductListTitle,
  ProductWrap,
} from './styles';
import { FormattedMessage } from 'react-intl';
import { ItemHead } from '../styles';
import { formatPrice } from '../../../../../modules/price';

export default ({
  product,
  isAList = false,
  isACart = false,
  infoPrice = 0,
  infoQuantity = 1,
  infoCurrency = '',
}) => {
  return (
    <ProductList isAList={isAList}>
      <ItemHead>
        <FormattedMessage
          id="messages.chat.messages.product_list_id"
          defaultMessage="Product ID # {id}"
          values={{
            id: product?.productId ?? '',
          }}
        />
      </ItemHead>
      {Array.isArray(product?.skus)
        ? product?.skus?.map((sku, skuIndex) => (
            <ProductListBody key={`${product?.productId}-productItem-sku-${sku}-${skuIndex}`}>
              {product?.images?.[0] ? (
                <img src={product.images?.[0]} alt={product?.productId || ''} />
              ) : (
                ''
              )}
              <ProductListInfo>
                <ProductListTitle href={sku?.url || '#'} target="_blank">
                  {product?.name || ''}
                </ProductListTitle>
                <ProductListSKU>
                  <FormattedMessage
                    id="messages.chat.messages.sku_id"
                    defaultMessage="SKU: {id}"
                    values={{
                      id: `${sku?.sellerSku} ${sku?.storeSku || ''} ${sku?.skuId || ''}`,
                    }}
                  />
                </ProductListSKU>
                <ProductWrap>
                  <ProductID>
                    <FormattedMessage
                      id="messages.chat.messages.product_id"
                      defaultMessage="Product ID:"
                    />
                    <span>{product?.productId ?? ''}</span>
                  </ProductID>
                  <ProductListPrice>
                    {isACart
                      ? `${infoQuantity} x ${formatPrice(
                          product?.store?.countryCode,
                          infoCurrency,
                          infoPrice,
                        )}`
                      : `${formatPrice(
                          product?.store?.countryCode,
                          sku?.currency || '',
                          sku?.price || '0.00',
                        )}`}
                  </ProductListPrice>
                </ProductWrap>
              </ProductListInfo>
            </ProductListBody>
          ))
        : ''}
    </ProductList>
  );
};
