import { createContext, useContext, useEffect, useState } from 'react';
import { setupWampHandler } from '../modules/wamp';
import { JwtTokenContext } from './JwtTokenContext';

export const WampHandlersContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [allWampHandlers, setAllWampHandlers] = useState({});
  const [wampHandlers, setWampHandlers] = useState({});
  const [message, setMessage] = useState<any>({});
  const [token] = useContext<any>(JwtTokenContext);

  let wampIsSet = false;

  // Wamp
  const globalWampHandlers = {
    // @ts-ignore
    __byType: (msg, kwargs = {}, ed = {}, sub = { topic: any }) => {
      try {
        if (msg?.[0] && msg?.[0]?.type && msg?.[0]?.type === 'ping') {
          return;
        }
        if (msg?.[0] && msg?.[0]?.type && allWampHandlers) {
          if ('health_check' in msg[0]) {
            return;
          }
          setMessage(msg[0]);
        }
      } catch (e) {
        console.error('Can\'t call WAMP Handler with message', msg);
      }
    },
  };

  // @ts-ignore
  const openConnection = ({ connectionWamp, session, host, realm, channel, handle }) => {
    // console.log('openConnection', { connectionWamp, session, host, realm, channel, handle });
  };

  const initialize = async () => {
    if (token) {
      if (!wampIsSet) {
        // noinspection JSIgnoredPromiseFromCall
        const installed = await setupWampHandler(globalWampHandlers, {
          onStart: null,
          onOpen: openConnection,
          onClose: null,
          onUserError: null,
          onInternalError: null,
        });
        let topic = '';
        let handler = null;
        let promises = [];
        for (const k in installed) {
          topic = Object.keys(installed[k]?.['system'])?.[0];
          handler = installed[k]?.['system']?.[topic];
          promises.push(handler.promise);
        }

        wampIsSet = true;
      }
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    initialize();
  }, [token]);

  useEffect(() => {
    setAllWampHandlers({ ...allWampHandlers, ...wampHandlers })
  }, [wampHandlers]);

  useEffect(() => {
    if (allWampHandlers?.[message?.type]) {
      allWampHandlers?.[message?.type](message);
    }
  }, [message]);

  return (
    <WampHandlersContext.Provider value={[wampHandlers, setWampHandlers]}>
      {children}
    </WampHandlersContext.Provider>
  );
};
