import { FormRow } from '../FormItems/FormRow';
import { AddButton, Container, RowsContainer } from './styles';
import FormCopyableList from '../FormItems/FormCopyableList';
import { FormattedMessage } from 'react-intl';

export const OutOfOfficeFormRenderContent = ({
  inputValues,
  requiredFields,
  setInputValues,
  autoReplyFormData,
}) => {
  const handleAddButton = () => {
    setInputValues({
      ...inputValues,
      settings: [
        ...inputValues.settings,
        {
          weekDays: [],
          timeRange: {
            from: '',
            till: '',
          },
        },
      ],
    });
  };
  const handleAddAutoreply = () => {
    let values = [
      {
        id: inputValues.autoReplyTextSequence?.length ?? 0,
        autoReplyTextSequenceText: '',
      },
    ];

    if (Array.isArray(inputValues.autoReplyTextSequence)) {
      values = [...inputValues.autoReplyTextSequence, ...values];
    }
    setInputValues({
      ...inputValues,
      autoReplyTextSequence: values,
    });
  };
  return (
    <Container>
      {autoReplyFormData?.map((step, index) => (
        <RowsContainer key={`autoreply-step-${index}`}>
          {step.form.rows.map(
            (formRow, formRowIndex) =>
              ('type' in formRow && formRow?.type === 'copyable' && (
                <div key={`autoreply-row-${formRowIndex}`}>
                  <FormCopyableList
                    key={`report-row-${formRowIndex}`}
                    index={index}
                    formRowIndex={formRowIndex}
                    inputValues={inputValues}
                    requiredFields={requiredFields}
                    setInputValues={setInputValues}
                    formRow={formRow}
                  />
                  {'type' in formRow && formRow?.id === 'autoReplyTextSequence' ? (
                    <AddButton variant="text" onClick={handleAddAutoreply}>
                      <FormattedMessage id="auto-reply.form.add_autoreply" />
                    </AddButton>
                  ) : (
                    <AddButton variant="text" onClick={handleAddButton}>
                      <FormattedMessage id="auto-reply.form.add_button" />
                    </AddButton>
                  )}
                </div>
              )) || (
                <FormRow
                  key={`autoreply-row-${formRowIndex}`}
                  index={index}
                  inputValues={inputValues}
                  requiredFields={requiredFields}
                  formRowIndex={formRowIndex}
                  setInputValues={setInputValues}
                  inputLabels={{}}
                  setInputLabels={() => {}}
                  formRow={formRow}
                  isPreview={false}
                />
              ),
          )}
        </RowsContainer>
      ))}
    </Container>
  );
};
export default OutOfOfficeFormRenderContent;
