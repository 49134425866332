import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Content, Loader, Message } from '../styles';
import { Copy, InnerContainer, MessageCopy, TextContainer } from './styles';
import DeliveryStatus from '../../DeliveryStatus';
import LastErrorMessage from '../../LastErrorMessage';
import Avatar from '../../Avatar';
import ErrorMessage from '../../ErrorMessage';
import ProductItem from '../Product/item';

export default ({ client, message, resend, customerDataStoreTimezone, more = null }) => {
  let messages: any = {};
  if (message?.body?.messages && typeof message?.body?.messages === 'object') {
    messages = { ...message, body: { ...message?.body, messages: [...message?.body.messages] } };
    messages?.body?.messages?.reverse();
  }

  return (
    <Message isClient={client} key={messages?._id?.$oid + messages?.sendId}>
      <Content>
        <InnerContainer>
          {!messages?.isClient && messages?.lastSendError && (
            <IconButton aria-label="resend" onClick={() => resend(messages?._id?.$oid)}>
              <RefreshIcon fontSize="small" />
            </IconButton>
          )}
          {(!messages?.delivery ||
            messages?.delivery === 'sending' ||
            messages?.delivery === 'sent2api' ||
            messages?.delivery === 'sent_to_api') &&
            !client && <Loader color="primary" src={'/img/loader-new.gif'} />}
          <TextContainer>
            <Avatar message={message} isClient={client} />
            {messages?.body?.messages
              ?.map(
                (msg) =>
                  (msg?.body?.product && <ProductItem product={msg?.body?.product} />) ||
                  (msg?.body?.text && (
                    <MessageCopy>
                      <Copy
                        dangerouslySetInnerHTML={{
                          __html: msg?.body?.text.replace(/\r\n|\r|\n/g, '<br />') || '',
                        }}
                      />
                    </MessageCopy>
                  )),
              )
              .reverse()}
          </TextContainer>
        </InnerContainer>
        {more}
        <DeliveryStatus
          isClient={client}
          message={messages}
          customerDataStoreTimezone={customerDataStoreTimezone}
        />
        <LastErrorMessage message={messages} />
        <ErrorMessage message={messages?.rawResponse?.process_msg ?? ''} />
      </Content>
    </Message>
  );
};
