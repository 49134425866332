import IconButton from '@mui/material/IconButton';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import { Table, Td, Th, Tr } from '../Table';
import useSortableData from '../../modules/useSortableData';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '@mui/material';
import { getData } from '../../modules/fetch';
import Templates from './templates';

// import Checkbox from '@mui/material/Checkbox';

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
  @media ${mediaQueries.desktop} {
    overflow-x: hidden;
  }
`;
const Logo = styled.div`
  width: 40px;
  height: 40px;
  margin: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default function DataGridDemo({ data }) {
  const intl = useIntl();
  const { items, requestSort, sortConfig } = useSortableData(data);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  // @ts-ignore
  const handleCheck = (id) => {
    console.debug('/components/StoresList/index.js:handleCheck', id);
  };

  const redirectLink = (businessId, wabaId) => {
    // https://business.facebook.com/wa/manage/message-templates/?business_id=333624351780420&waba_id=102294675951337
    return `https://business.facebook.com/wa/manage/message-templates/?business_id=${businessId}&waba_id=${wabaId}`;
  };
  const handleRedirect = (businessId, wabaId) => {
    window.open(redirectLink(businessId, wabaId), '_blank');

    return true;
  };

  const handleSync = (platform, storeId) => {
    getData(`/platform/${platform}/sync/template/${storeId}`)
      .then((data) => data && alert(intl.formatMessage({
        id: 'templatesManagement.storeList.sync.success',
        defaultMessage: 'Synchronization of templates successful.'
      })))
      .catch(() => alert(intl.formatMessage({
        id: 'templatesManagement.storeList.sync.fail',
        defaultMessage: 'Synchronization of templates fail.'
      })));

    return true;
  };

  return (
    <>
      <Container>
        <Table>
          <thead>
          <Tr>
            {/*<Th></Th>*/}
            <Th>
              <FormattedMessage
                id="templatesManagement.storeList.table.platform"
                defaultMessage="Platform"
              />
            </Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('name')}
                className={getClassNamesFor('name')}
              >
                <FormattedMessage
                  id="templatesManagement.storeList.table.shop"
                  defaultMessage="Shop Name"
                />
              </button>
            </Th>
            <Th>
              <FormattedMessage
                id="templatesManagement.storeList.table.amount"
                defaultMessage="Amount"
              />
            </Th>
            <Th>
              <FormattedMessage
                id="templatesManagement.storeList.table.actions"
                defaultMessage="Actions"
              />
            </Th>
          </Tr>
          </thead>
          <tbody>
          {items.map((item, index) => {
            return (
              <Tr key={`table-tr-${index}`}>
                {/*<Td>*/}
                {/*  <Checkbox color="primary" value="agree" onChange={() => handleCheck(item.id)} />*/}
                {/*</Td>*/}
                <Td>
                  <Logo>
                    <img src={`/pics/${item.provider ?? 'default'}_small.png`} alt="" />
                  </Logo>
                </Td>
                <Td>
                  <Link href={redirectLink(item?.rawData?.business?.id, item?.rawData?.waba?.id)} target={'_blank'}>
                    {item.name}&nbsp;
                    {item.countryCode.toUpperCase()}
                  </Link>
                </Td>
                <Td>
                  <Templates templates={item.messageTemplates} />
                </Td>
                <Td>
                  <IconButton
                    aria-label="open"
                    onClick={() => {
                      handleRedirect(item?.rawData?.business?.id, item?.rawData?.waba?.id);
                    }}
                  >
                    <OpenInNewOutlinedIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    aria-label="sync"
                    onClick={() => {
                      handleSync(item?.provider, item?._id?.$oid);
                    }}
                  >
                    <SyncOutlinedIcon fontSize="small" />
                  </IconButton>
                </Td>
              </Tr>
            );
          })}
          </tbody>
        </Table>
      </Container>
    </>
  );
}
