import { useContext, useEffect, useState } from 'react';
import { BackButton, ButtonsContainer, Center, Container, SaveButton } from './styles';
import {
  useAddFeedbackMutation,
  useFetchTemplateFeedbackQuery,
  useUpdateFeedbackMutation,
} from '../../api/feedbackAPI';
import Error from '../../components/Error';
import { setValuesFormTemplate } from '../../api/setValuesFormTemplate';
import { FormattedMessage } from 'react-intl';
import { standardJsonResponseSync } from '../../modules/fetch';
import { logMessage } from '../../logger';
import FeedbackFormRenderContent from '../../components/FeedbackFormRenderContent';
import { MessagesPropsContext } from '../../context/MessagesPropsContext';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

interface FeedbackFormProps {
  onClose: () => void;
  customerId: string;
}

interface IFeedback {
  _id?: { $oid: string };

  [key: string]: any;
}

export const FeedbackForm = ({ onClose, customerId }: FeedbackFormProps) => {
  const [inputValues, setInputValues] = useState({});
  const [inputLabels, setInputLabels] = useState({});
  const [requiredFields, setRequiredFields] = useState({});
  const [error, setError] = useState('unknown.error');
  const [, , , , selectedFeedbackForm, , isUpdate] = useContext(MessagesPropsContext);
  const {
    data: templateFeedback,
    error: errorTemplate,
    isError: isErrorTemplate,
  } = useFetchTemplateFeedbackQuery(customerId);
  const [addFeedback] = useAddFeedbackMutation();
  const [updateFeedback] = useUpdateFeedbackMutation();

  useEffect(() => {
    if (isErrorTemplate) {
      let response = { ...errorTemplate };
      let result;
      try {
        result = standardJsonResponseSync(response);
        setError('unknown.error');
      } catch (ResponseError) {
        console.warn('ResponseError', ResponseError);
        setError(ResponseError.message);
      }

      logMessage('result', result);
    }
  }, [errorTemplate, isErrorTemplate]);

  useEffect(() => {
    let temp;
    if (isUpdate) {
      temp = selectedFeedbackForm ?? {};
    } else {
      temp = setValuesFormTemplate(templateFeedback?.data ?? {});
    }
    setInputValues(temp.inputValues);
    setInputLabels(temp.inputLabels);
    setRequiredFields(temp.requiredFields);
  }, [selectedFeedbackForm, templateFeedback]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let feedback: IFeedback = Object.assign({}, inputLabels, inputValues);
    const id = feedback._id?.$oid;
    delete feedback._id;
    delete feedback.ownerId;
    delete feedback.customer;
    delete feedback.customerId;
    delete feedback.store;
    delete feedback.createdAt;
    delete feedback.updatedAt;
    delete feedback.isEditable;

    try {
      let response: any;
      if (isUpdate && id) {
        response = await updateFeedback({ id, feedback });
      } else {
        response = await addFeedback({ id: customerId, feedback });
      }

      if ('error' in response && 'status' in response.error && response.error.status !== 200) {
        const errorData = (response.error as FetchBaseQueryError).data as { errors?: any[] };
        const errors = errorData?.errors;
        if (errors) {
          const message = errors.map((item) => {
            const regex = /(\w+)\]$/g;
            const exec = regex.exec(item.path);
            const field = exec?.[1];
            if (field) {
              let fieldName = String(field).replace(/([-_]\w)/g, (g) => ' ' + g[1].toUpperCase());
              fieldName = fieldName.slice(0, 1).toUpperCase() + fieldName.slice(1);
              return `${fieldName}: ${item.message}`;
            }
          });
          alert('Error' + '\n' + message.join('\n'));
        } else {
          alert('Error' + '\n' + JSON.stringify(response));
        }
      } else if ('data' in response && response.data.meta?.statusCode === 200) {
        onClose();
      }
    } catch (error) {
      console.error('Submission error', error);
    }
  };

  return (
    <Container>
      {!templateFeedback?.data && (
        <Center>
          <FormattedMessage id={'modal.loading'} defaultMessage={'Loading...'} />
        </Center>
      )}
      {(isErrorTemplate && <Error message={error ?? 'unknown.error'} />) ||
        (templateFeedback?.data?.length ? (
          <form autoComplete="off" onSubmit={handleFormSubmit}>
            <FeedbackFormRenderContent
              inputValues={inputValues}
              requiredFields={requiredFields}
              setInputValues={setInputValues}
              feedbackFormData={templateFeedback?.data}
            />
            <ButtonsContainer>
              <BackButton onClick={onClose}>
                <FormattedMessage id="feedback.popup.button.cancel" />
              </BackButton>
              <SaveButton type="submit">
                <FormattedMessage id="feedback.popup.button.save" />
              </SaveButton>
            </ButtonsContainer>
          </form>
        ) : null)}
    </Container>
  );
};

export default FeedbackForm;
