import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  max-height: 400px;
  padding: 16px 14px;
  border: 2px solid rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
