import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { retrieve, store } from '../../modules/storage';
import { useIntl } from 'react-intl';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getData } from '../../modules/fetch';
import { GlobalUpdateQueryContext } from '../../context/GlobalUpdateQueryContext';
import { logMessage } from '../../logger';

const Form = styled.form`
  width: 100%;
  display: flex;
`;

const InputQueryContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: start;
  height: 25px;

  .MuiInputBase-root::before {
    border-bottom: none !important;
  }
`;

const StyledInput = styled(TextField)`
  height: 25px;
  width: 200px !important;
  padding: 0;

  input {
    font-size: 13px;
  }

  ::before {
    display: none;
  }

  ::after {
    display: none;
  }
`;

const InboxQuery = () => {
  const [tags, setTags] = useState<any>([]);
  const [query, setQuery] = useState<string>(retrieve.string('inboxQuery'));
  const intl = useIntl();
  const [globalUpdateQuery] = useContext(GlobalUpdateQueryContext);

  const getTags = () => {
    getData('/tags/listings')
      .then((data) => {
        if (data)
          setTags(data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleChangeAutocomplete = (event, value) => {
    logMessage('event', event);
    if (value) {
      value = query + ' #' + value.replace(' ', '_');
      setQuery(value);
      store.string('inboxQuery', value);
    } else {
      setQuery('');
      store.string('inboxQuery', '');
    }
    globalUpdateQuery.handleUpdateQuery(value);
  };

  const handleChangeInput = (event) => {
    let value = event.target.value;
    setQuery(value);
    store.string('inboxQuery', value);
    if (event.keyCode === 13) {
      globalUpdateQuery.handleUpdateQuery(value);
    }
  };
  const handlePaste = (event) => {
    setQuery('#' + event.clipboardData.getData('text/plain'));
    store.string('inboxQuery', '#' + event.clipboardData.getData('text/plain'));
    globalUpdateQuery.handleUpdateQuery('#' + event.clipboardData.getData('text/plain'));
  };

  return (
    <Form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <InputQueryContainer>
        <Autocomplete
          id="tags-filled"
          options={tags.map((option) => option.text)}
          freeSolo
          value={query}
          onChange={handleChangeAutocomplete}
          onKeyDown={handleChangeInput}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip size="small" variant="filled" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <StyledInput
              {...params}
              fullWidth={true}
              margin="none"
              variant="standard"
              value={query}
              placeholder={intl.formatMessage({
                id: 'messages.inboxQuery.search',
                defaultMessage: 'messages.inboxQuery.search',
              })}
              onFocus={getTags}
              onPaste={handlePaste}
              onChange={handleChangeInput}
            />
          )}
        />
      </InputQueryContainer>
    </Form>
  );
};

export default React.memo(InboxQuery);
