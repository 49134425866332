import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Response from './response';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import { getData } from '../../modules/fetch';
import { FormattedMessage } from 'react-intl';
import Error from '../Error';
import { useUpdateAccountMutation } from '../../api/accountAPI';
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import '@webscopeio/react-textarea-autocomplete/style.css';
import {
  MixedTagsContainer,
  Tokens,
  TokensInner,
} from './styles';

const Container = styled.div`
  background-color: #fff;
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  padding: 25px 20px 10px;
  border-top: 1px solid #ebeff2;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
  border-top: 1px solid #ebeff2;
  button {
    margin: 0 10px;
  }
`;
const Form = styled.form`
  width: 100%;

  ${Buttons} {
    border-top: none;
  }
`;
const StyledTextField = styled(TextField)`
  margin-bottom: 20px !important;
`;
const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-top: 1px solid #ccc;
`;
const AutoResponses = ({ data = [], onClose, handleMessageSend, ...props }) => {
  // Deep copy https://stackoverflow.com/questions/71041929/uncaught-typeerror-cannot-assign-to-read-only-property-amount-of-object-ob
  let responses = JSON.parse(JSON.stringify(data));
  const [tokens, setTokens] = useState([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const [, setComplete] = useState(false);
  const [title, setTitle] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [isValid, setIsValid] = useState<boolean>(true);
  const rtaRef = useRef<any>();

  const [ updateAccount ] = useUpdateAccountMutation();

  const handleMessageChange = useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  useEffect(() => {
    getTokens();
  }, []);

  const getTokens = () => {
    getData('/tokens/listings')
      .then((data) => {
        if (data)
          setTokens(data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateFastResponses = async (responses) => {
    await updateAccount({
      account: {
        fastResponses: responses,
      },
    });
    setComplete(true);
    setShowAddNew(false);
    handleCancel();
  };
  const handleDelete = (index) => {
    responses.splice(index, 1);
    updateFastResponses(responses);
  };
  const handleUpdate = (index, message) => {
    responses[index].message = message;
    updateFastResponses(responses);
  };
  const handleTitleChange = (event) => {
    if (!event.target.value) {
      setIsValid(false);
    }
    setTitle(event.target.value);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!title) {
      setIsValid(false);
      return;
    }
    responses = [...responses, {
      title: title,
      message: message,
    }];
    updateFastResponses(responses);
    setIsValid(true);
  };
  const handleCancel = () => {
    setShowAddNew(false);
    setIsValid(true);
  };
  const handleClose = (e?) => {
    if (e) {
      e.preventDefault();
      e.target.dataset.id === 'responses-container' && onClose();
    }
  };
  const handleAddTag = (token) => {
    const tokenString = `[[{"value":"${token}"}]]`;
    const caretPosition = rtaRef.current.getCaretPosition();
    const newMessage = message.slice(0, caretPosition) + tokenString + message.slice(caretPosition);
    setMessage(newMessage);
  };
  const Item = ({ entity: { name } }) => <div>{name}</div>;
  return (
    <Container {...props} onClick={handleClose} data-id="responses-container">
      <Title>
        <FormattedMessage id="messages.chat.responses.title" defaultMessage="Fast Responses" />
      </Title>
      {responses?.map((item, index) => (
        <Response
          data={item}
          index={index}
          tokens={tokens}
          handleSend={handleMessageSend}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
          onClose={onClose}
        />
      ))}
      {showAddNew ? (
        <Form autoComplete="off" onSubmit={handleFormSubmit}>
          <TextFieldContainer>
            <StyledTextField
              id="title"
              label="Title"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              autoComplete="none"
              onChange={handleTitleChange}
              required
            />
            {!isValid && <Error message="error.title.required" />}
            <MixedTagsContainer>
              <ReactTextareaAutocomplete
                onChange={handleMessageChange}
                loadingComponent={() => <span>Loading</span>}
                value={message}
                ref={rtaRef}
                trigger={{
                  "@": {
                    dataProvider: () => {
                      const output = tokens.map((token) => {
                        return { name: token, char: ` [[{"value":"${token}"}]]`}
                      });
                      return output;
                    },
                    component: Item,
                    output: (item) => {
                      return item.char;
                    }
                  }
                }}
              />
            </MixedTagsContainer>
            <Tokens>
              <TokensInner>
                {tokens.map((token) => (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleAddTag(token)}
                  >
                    {token}
                  </Button>
                ))}
              </TokensInner>
            </Tokens>
          </TextFieldContainer>
          <Buttons>
            <Button variant="contained" color="primary" type="submit" onClick={handleFormSubmit}>
              <FormattedMessage id="messages.chat.responses.button.save" defaultMessage="Save" />
            </Button>
            <Button onClick={handleCancel}>
              <FormattedMessage
                id="messages.chat.responses.button.cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </Buttons>
        </Form>
      ) : (
        <Buttons>
          <Button size="large" onClick={onClose}>
            <FormattedMessage id="messages.chat.responses.button.close" defaultMessage="Close" />
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setTitle('');
              setMessage('');
              setShowAddNew(true);
            }}
          >
            <FormattedMessage id="messages.chat.responses.button.new" defaultMessage="Add New" />
          </Button>
        </Buttons>
      )}
    </Container>
  );
};

export default React.memo(AutoResponses);
