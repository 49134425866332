import { Container, InnerContainer, StyledIconButton } from './styles';
import FormCopyableItem from '../FormCopyableItem';
import { FormattedMessage } from 'react-intl';
import { TitleBlock } from '../FormRow/styles';
import DeleteIcon from '@mui/icons-material/DeleteForever';

export const FormCopyableList = ({
  index,
  formRowIndex,
  inputValues,
  requiredFields,
  setInputValues,
  formRow,
}) => {
  const handleDelete = (i) => {
    const updatedAutoreply = [...inputValues.autoReplyTextSequence];
    updatedAutoreply.splice(i, 1);
    setInputValues({
      ...inputValues,
      autoReplyTextSequence: [...updatedAutoreply],
    });
  };
  return (
    <Container>
      {inputValues[formRow.id]?.map((_, copyableItemIndex) => {
        return (
          <div key={`copyable-item-${copyableItemIndex}`}>
            <TitleBlock>
              {formRow?.title && (
                <InnerContainer>
                  <div>
                    <FormattedMessage id={formRow?.title} defaultMessage={formRow?.title} />{' '}
                    <span>{copyableItemIndex + 1}</span>
                  </div>
                  {formRow.removable && (
                    <StyledIconButton
                      aria-label="hide"
                      onClick={() => handleDelete(copyableItemIndex)}
                    >
                      <DeleteIcon />
                    </StyledIconButton>
                  )}
                </InnerContainer>
              )}
            </TitleBlock>
            <FormCopyableItem
              index={index}
              formRowIndex={formRowIndex}
              inputValues={inputValues}
              requiredFields={requiredFields}
              setInputValues={setInputValues}
              formRow={formRow}
              copyableItemIndex={copyableItemIndex}
            />
          </div>
        );
      })}
    </Container>
  );
};
export default FormCopyableList;
